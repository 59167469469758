import { mainSuite } from '@services/ServiceFactory';
import './FakeOnboard.scss';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TwtAuthVersion } from '@storyverseco/svs-navbar';
import { AppActionType, useAppDispatch } from '@context/AppContext';

enum OnboardState {
  Idle = 'Idle',
  CheckWalletConnect = 'CheckWalletConnect',
  WalletConnected = 'WalletConnected',
  // WalletNotConnected = 'WalletNotConnected',
  CheckTwitterConnected = 'CheckTwitterConnected',
  TwitterConnected = 'TwitterConnected',
  // TwitterNotConntected = 'TwitterNotConntected',
  ConnectWallet = 'ConnectWallet',
  ConnectTwitter = 'ConnextTwitter',
}

const DEFAULT_REDIRECT = '/abc'; // whatever just to default to viewer

export const FakeOnboard = () => {
  const navigate = useNavigate();

  const appDispatch = useAppDispatch();

  const [state, setState] = useState(OnboardState.Idle);

  const onClick = async () => {
    await mainSuite.navbarService.api.logIn();
    setState(OnboardState.WalletConnected);
  };

  // Check For Wallet Connected
  useEffect(() => {
    if (state !== OnboardState.Idle) {
      return;
    }
    const fetchWalletConnected = async () => {
      const isConnected = await mainSuite.navbarService.api.isLoggedIn();
      if (isConnected) {
        setState(OnboardState.WalletConnected);
      } else {
        setState(OnboardState.ConnectWallet);
      }
    };
    fetchWalletConnected();
    setState(OnboardState.CheckWalletConnect);
  }, [state]);

  // On Wallet Connected
  useEffect(() => {
    if (state !== OnboardState.WalletConnected) {
      return;
    }
    setState(OnboardState.CheckTwitterConnected);
  }, [state]);

  useEffect(() => {
    if (state !== OnboardState.CheckTwitterConnected) {
      return;
    }
    const fetchTwitterConnected = async () => {
      const isConnected = await mainSuite.navbarService.api.twitterService.auth.get();
      if (isConnected) {
        setState(OnboardState.TwitterConnected);
      } else {
        setState(OnboardState.ConnectTwitter);
      }
    };
    fetchTwitterConnected();
    setState(OnboardState.CheckTwitterConnected);
  }, [state]);

  // On Twitter Connected
  useEffect(() => {
    if (state !== OnboardState.TwitterConnected) {
      return;
    }
    appDispatch({
      type: AppActionType.UpdateShowOnboard,
      showOnboard: false,
    });

    // @TODO: We need to take the redirect route from where the user enters
    navigate(DEFAULT_REDIRECT);
  }, [state]);

  useEffect(() => {
    if (state !== OnboardState.ConnectTwitter) {
      return;
    }
    const connectTwitter = async () => {
      const isConnected = await mainSuite.navbarService.api.twitterService.auth.logIn({ authVersion: TwtAuthVersion.V1Write });
      if (isConnected) {
        setState(OnboardState.TwitterConnected);
      } else {
        setState(OnboardState.ConnectTwitter);
      }
    };
    connectTwitter();
  }, [state]);

  const disableBtn = useMemo(() => !(state == OnboardState.ConnectWallet), [state]);

  console.log('Fake Onboard', { state });

  return (
    <div className="fake">
      <div className="page">
        <button className="login-btn" onClick={onClick} disabled={disableBtn}>
          Fake Privy Login
        </button>
      </div>
    </div>
  );
};
