import { Page } from '../Page';
import './MintHomePage.scss';
import '../mint-v2/components/CollectionsForm.scss';

import MintHomeLayout from './layouts/MintHomeLayout';
import { Carousel } from 'react-responsive-carousel';
import { ItemFeaturedProps } from './components/ItemFeatured';
import Item from './components/Item';

import { useEffect, useCallback, useState, useRef, useMemo } from 'react';
import Sale from '@common/Sale';
import { TransitioningSection } from '@components/transitioning-section';
import { mainSuite } from '@services/ServiceFactory';
import { debug } from '@common/LogWrapper';
import { Optional } from '@storyverseco/svs-types';
import { CollectionData } from '@assets/homepage';
import { ModalDrawer } from '@components/modaldrawer/ModalDrawer';
import ItemInfo from './components/ItemInfo';
import { HostedApp } from '@components/hosted-app/HostedApp';
import { AppActionType, useAppDispatch, useAppState } from '@context/AppContext';

import { useNavigate, useParams } from 'react-router-dom';
import debounce from '@common/Debounce';
import { PathParam } from '@common/PathParam';
import { IStory } from '@storyverseco/svs-story-suite';

const log = debug('app:pages:MintHomePage');

// let slideSnapshotsRef = [];

let lastGemLoading = null;

const getCollectionData = (sale: Sale): Optional<CollectionData> => {
  // @TODO: Type of Sale is no longer safe since it's updated dynamically
  const { authorInfo, listing } = sale as any;
  if (authorInfo && listing) {
    return {
      thumb: listing.banner,
      url: listing.url,
      avatar: authorInfo.avatar,
      author: authorInfo.name,
      footnote: authorInfo.footnote,
    };
  }

  return undefined;
};

type SaleItemTypeMap = Record<string, ItemFeaturedProps['type']>;

const scrollDeltaThreshold = 10;

export interface Feed {
  id: string;
  created_at: string;
  username: string;
  gemname: string;
  creator_address: string;
  story_index: string;
  story_image?: string;
  content_url: string;
  profile_url: string;
  profile_image_url: string;
  gemimage: string;
}

const getPagePath = (gemname: string, slash = '/') => `${slash}${gemname.replace(/ /g, '_').toLocaleLowerCase().trim()}`;

const saleStoryCache: Record<string, IStory> = {};

const gameUrl = (() => {
  const hostname = window.location.hostname;
  if (hostname.includes('gem-dev')) {
    return 'https://d70g6vzrdmee5.cloudfront.net/ver-carles-viewer/index.html?iframed=true';
  }
  if (hostname.includes('localhost')) {
    return 'http://localhost:3002?iframed=true';
  }
  return 'https://d70g6vzrdmee5.cloudfront.net/ver-gemz-viewer/index.html?iframed=true';
})();

export function MintHomePage({ showCreate = false }: { showCreate?: boolean }) {
  const { saleService } = mainSuite;

  const navigate = useNavigate();

  const appDispatch = useAppDispatch();

  const appState = useAppState();

  const params = useParams();

  // const [isLoadingStory, setIsLoadingStory] = useState(false);

  const [currentSlide, setCurrentSlide] = useState(0);

  const [selectedSlideForSnapshot, setSelectedSlideForSnapshot] = useState(-1);

  const [feed, setFeed] = useState<Feed[]>([]);

  const carouselRef = useRef<Carousel>();

  const [viewerReady, setViewerReady] = useState(false);

  const [disableScroll, setDisableScroll] = useState(appState.isCreate);

  // const [showModal, setShowModal] = useState(false);
  // const onModalOpen = useCallback(() => {
  //   showModalRef = true;
  //   setShowModal(true);
  // }, []);
  // const onModalClose = useCallback(() => {
  //   showModalRef = false;
  //   setShowModal(false);
  // }, []);

  useEffect(() => {
    if (appState.showOnboard) {
      navigate('/');
    }
  }, []);

  const fetchFeed = async () => {
    const response = await fetch('https://pipeline.beta.pnk.one/gems/feed/content');
    const data = (await response.json()) as Feed[];

    if ((data as any).error) {
      return;
    }

    data.sort((a, b) => {
      if (getPagePath(a.gemname, '') === params[PathParam.SalePath]) {
        return -1;
      }
      return 1;
    });
    setFeed(data);
  };

  // Load feed
  useEffect(() => {
    fetchFeed().then(() => {
      setCurrentSlide(0);
    });
  }, [params]);

  const currentSale: Feed | undefined = feed?.[currentSlide];

  // Add viewer callback
  useEffect(() => {
    const viewerCallback = async () => {
      console.warn('Viewer is ready');
      setViewerReady(true);
      // mainSuite.navbarService.sendClientEvent('PLAY_STORY', storyData);
      // Send event for the game to load the story (fetch data before hand)
    };
    const storyReadyCallback = (viewerGemName: string) => {
      console.warn('storyReady');
      console.warn('>>> website - storyReady callBack', lastGemLoading, '->', viewerGemName);

      if (lastGemLoading === viewerGemName) {
        console.warn('>>> setting showGame to true...');
        appDispatch({
          type: AppActionType.GameIsPlaying,
          showGame: true,
        });

        // failsafe in case for some reason showGame will be set to false afterwards
        window.setTimeout(() => {
          if (appState.showGame === false) {
            if (lastGemLoading === viewerGemName) {
              appDispatch({
                type: AppActionType.GameIsPlaying,
                showGame: true,
              });
            }
          }
        }, 10000);
      } else {
        console.error('>>> gems did not match on story_ready');
      }
    };

    const storyEndCallback = () => {
      console.warn('storyEnd');
      appDispatch({
        type: AppActionType.GameIsPlaying,
        showGame: false,
      });
    };

    const buyGemCallback = async () => {
      console.log('buyGemCallback');
      if (!currentSale) {
        console.error(`Cannot buy gem without 'currentSale'`);
        return;
      }
      const wallet = await mainSuite.navbarService.api.getWallet();
      if (!wallet) {
        console.error(`Cannot buy gem without 'wallet'.`);
        return;
      }
      const twitter = await mainSuite.navbarService.api.twitterService.auth.get();
      if (!twitter) {
        console.error(`Cannot buy gem without 'twitter'.`);
        return;
      }
      try {
        await fetch('https://pipeline.beta.pnk.one/gems/minted', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: {
              creatorAddress: currentSale.creator_address,
              storyId: currentSale.story_index,
              ownerAddress: wallet.address,
              txhash: 'txhash',
              confirmed: false,
              username: twitter.handle,
              gemName: currentSale.gemname,
            },
          }),
        });
        mainSuite.navbarService.sendClientEvent('PURCHASE', 'success');
      } catch {
        mainSuite.navbarService.sendClientEvent('PURCHASE', 'error');
      }
    };

    const storyScrollCallback = async (opts: { deltaY: number; webkitDirectionInvertedFromDevice: boolean }) => {
      // console.warn('>>> STORY_SCROLL', opts.deltaY, opts.webkitDirectionInvertedFromDevice);
      doScrollCustom(opts);
    };

    const storySnapshotCallback = async (snapshot: string) => {
      if (!snapshot) {
        return;
      }

      if (!currentSale) {
        throw new Error(`It should be impossible to get a snapshot without 'currentSale'.`);
      }

      // if we just created the story, then we upload the snapshot to S3 then update db
      if (appState.isCreate) {
        const imgUrl = await uploadFileWithSignedPost({
          b64: snapshot,
          key: `${currentSale.creator_address}_${currentSale.story_index}_${getPagePath(currentSale.gemname, '')}.jpg`,
        });

        // @TODO: save to DB
        await fetch('https://pipeline.beta.pnk.one/gems/feed/img', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: {
              id: currentSale.id,
              story_image: imgUrl,
            },
          }),
        });

        console.log({ imgUrl });

        appDispatch({
          type: AppActionType.UpdateIsCreate,
          isCreate: false,
        });

        setDisableScroll(false);
      }

      // disabled for now
      // slideSnapshotsRef[currentSlide] = snapshot;
      // console.warn('>>>', slideSnapshotsRef);

      setSelectedSlideForSnapshot(currentSlide);
    };

    const onAiStoryCreated = async ({ story, gemname }) => {
      const wallet = await mainSuite.navbarService.api.getWallet();
      if (!wallet) {
        console.error(`Cannot buy gem without 'wallet'.`);
        return;
      }
      const twitter = await mainSuite.navbarService.api.twitterService.auth.get();
      if (!twitter) {
        console.error(`Cannot buy gem without 'twitter'.`);
        return;
      }
      try {
        const feedResponse = await fetch('https://pipeline.beta.pnk.one/gems/feed/content');
        const feedData = (await feedResponse.json()) as Feed[];

        const creatorFeed = feedData.filter((item) => item.creator_address.toLowerCase() === wallet.address.toLowerCase());

        const mintData = {
          data: {
            creatorAddress: wallet.address,
            storyId: creatorFeed.length, // todo carles: this need to be feed.length
            ownerAddress: wallet.address,
            txhash: 'txhash',
            confirmed: false,
            username: twitter.handle,
            gemName: gemname,
            storyData: encodeURI(JSON.stringify(story)),
          },
        };

        const response = await fetch('https://pipeline.beta.pnk.one/gems/minted', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(mintData),
        });

        if ((await response.json()).error) {
          throw new Error('Something went wrong!');
        }

        console.warn('>>> https://pipeline.beta.pnk.one/gems/minted', response, mintData);

        // navigate back to new created gem in feed
        // todo: this should navigate to the feed entry with the gem we just created
        // todo: but does not seem to be properly generated for some reason
        const newGemRoute = gemname.toLowerCase().split(' ').join('_');
        fetchFeed().then(() => {
          appDispatch({
            type: AppActionType.UpdateIsCreate,
            isCreate: true,
          });
          navigate(`/${newGemRoute}`);
        });
      } catch (e) {
        console.log(e);
      }
    };

    mainSuite.navbarService.on('CAI_IS_HERE', viewerCallback);
    mainSuite.navbarService.on('STORY_READY', storyReadyCallback);
    mainSuite.navbarService.on('BUY_GEM', buyGemCallback);
    mainSuite.navbarService.on('STORY_END', storyEndCallback);
    mainSuite.navbarService.on('STORY_SCROLL', storyScrollCallback);
    mainSuite.navbarService.on('STORY_SNAPSHOT', storySnapshotCallback);
    mainSuite.navbarService.on('STORY_GENERATED', onAiStoryCreated);

    return () => {
      mainSuite.navbarService.off('CAI_IS_HERE', viewerCallback);
      mainSuite.navbarService.off('STORY_READY', storyReadyCallback);
      mainSuite.navbarService.off('BUY_GEM', buyGemCallback);
      mainSuite.navbarService.off('STORY_END', storyEndCallback);
      mainSuite.navbarService.off('STORY_SCROLL', storyScrollCallback);
      mainSuite.navbarService.off('STORY_SNAPSHOT', storySnapshotCallback);
      mainSuite.navbarService.off('STORY_GENERATED', onAiStoryCreated);
    };
  }, [currentSale]);

  useEffect(() => {
    if (!feed?.length) {
      return;
    }
    if (!viewerReady) {
      return;
    }
    if (currentSlide === -1) {
      return;
    }
    if (showCreate) {
      return;
    }

    const currentEntry = feed[currentSlide];
    console.log({ currentEntry });

    let stale = false;
    const loadStory = async () => {
      const storyDataUrl = currentEntry.content_url.includes('.json')
        ? currentEntry.content_url
        : currentEntry.content_url.replace('character-pass', 'pipeline.beta').replace('view', 'story') + '/13';
      console.log({ storyDataUrl });
      const response = await fetch(storyDataUrl);
      const storyData = await response.json();

      // trying to delay game loading a bit for a smoother feeling
      await new Promise((resolve) => setTimeout(resolve, 666));

      if (stale) {
        return;
      }
      console.log('>>>', storyData.id, { storyData });
      mainSuite.navbarService.sendClientEvent('PLAY_STORY', storyData);

      mainSuite.navbarService.sendClientEvent('SET_GEM', {
        image: currentEntry.gemimage,
        name: currentEntry.gemname,
      });

      lastGemLoading = currentEntry.gemname;
    };

    console.warn('>>> loadStory');

    loadStory();

    return () => {
      stale = true;
    };
  }, [viewerReady, currentSlide, feed, showCreate]);

  // todo(jb)
  // todo: when we use carousel by internal swiping functionality, slides are seamless and infinite
  // todo: but when we use our custom stuff, slides dont behave seamless. we need to figure out a solution for this.

  const goToNextSlide = useMemo(() => debounce(() => carouselRef.current?.increment(), 700, true), [carouselRef]);
  const goToPrevSlide = useMemo(() => debounce(() => carouselRef.current?.decrement(), 700, true), [carouselRef]);

  // todo(jb): tried to use this instead but no luck
  // const goToNextSlide = useMemo(() => debounce(() => carouselRef.current?.onSwipeForward(), 700, true), [carouselRef]);
  // const goToPrevSlide = useMemo(() => debounce(() => carouselRef.current?.onSwipeBackwards(), 700, true), [carouselRef]);

  const doScrollCustom = (opts: { deltaY: number; webkitDirectionInvertedFromDevice: boolean }) => {
    if (disableScroll) {
      return;
    }

    if (!carouselRef.current) {
      return;
    }

    if (Math.abs(opts.deltaY) < scrollDeltaThreshold) {
      return;
    }
    let deltaY = opts.deltaY;
    if (opts.webkitDirectionInvertedFromDevice) {
      deltaY = -deltaY;
    }
    const { selectedItem, itemSize } = carouselRef.current.state;
    if (opts.deltaY < -scrollDeltaThreshold && selectedItem < itemSize - 1) {
      // we want the slides to be infinite and seamless
      goToNextSlide();
    }
    if (opts.deltaY > scrollDeltaThreshold && selectedItem > 0) {
      // we want the slides to be infinite and seamless
      goToPrevSlide();
    }
  };

  // handle scroll events for carousel
  const doScroll = useCallback(
    (e: WheelEvent) => {
      doScrollCustom({
        deltaY: e.deltaY,
        webkitDirectionInvertedFromDevice: (e as any).webkitDirectionInvertedFromDevice,
      });
    },
    [carouselRef, goToNextSlide, goToPrevSlide],
  );

  // listen to scroll events
  useEffect(() => {
    if (!viewerReady) {
      return;
    }

    window.addEventListener('wheel', doScroll, {
      capture: false,
      passive: true,
    });

    return () => {
      window.removeEventListener('wheel', doScroll, {
        capture: false,
      });
    };
  }, [doScroll, viewerReady]);

  // replace current URL
  useEffect(() => {
    if (!appState.initialSearchParams) {
      return;
    }
    if (!currentSale) {
      return;
    }
    if (showCreate) {
      return;
    }

    let url = getPagePath(currentSale.gemname);

    if (appState.initialSearchParams.size) {
      url += '?' + appState.initialSearchParams.toString();
    }

    window.history.replaceState({}, null, url);
  }, [currentSale, appState.initialSearchParams, showCreate]);

  // show creator page
  useEffect(() => {
    if (!showCreate) {
      return;
    }

    log('show creator page');
    mainSuite.navbarService.sendClientEvent('GOTO_GAME_CREATOR');
    appDispatch({
      type: AppActionType.GameIsPlaying,
      showGame: true,
    });
  }, [showCreate]);

  const loaded = Boolean(feed.length && currentSale);

  console.log({
    feed,
    currentSale,
    loaded,
  });

  return (
    <Page className="home-base" title="Collectible Stories" introBg fixedNavbar={true}>
      <MintHomeLayout className="home-state-page-layout" title="Storyverse" subtitle={`Collect & play to watch your story unfold`}>
        {loaded && (
          <Carousel
            ref={carouselRef}
            autoFocus={true}
            axis={'vertical'}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            infiniteLoop={false} // disabled to avoid scroller jumping craziness
            dynamicHeight={false}
            // autoplay
            autoPlay={false}
            interval={4000}
            stopOnHover={false}
            // swiping
            swipeable={true}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={5} // default is 5
            verticalSwipe={'standard'} // standard or natural -> natural is inverse direction
            emulateTouch={true}
            transitionTime={500}
            animationHandler={'slide'} // 'slide' | 'fade' | AnimationHandler;
            useKeyboardArrows={true}
            // onSwipeStart={() => {}}
            // onSwipeEnd={() => {}}
            // onSwipeMove={(e: React.TouchEvent) => true}
            // selecting
            // selectedItem={currentSlideRef}
            onChange={(index) => {
              appDispatch({
                type: AppActionType.GameIsPlaying,
                showGame: false,
              });

              // todo carles/cai: we need to use some kind of debounce to avoid loading stories while we are changing slide
              // window.setTimeout(() => setCurrentSlide(index), 500);
              setCurrentSlide(index);
            }}
          >
            {(feed || []).map((sale, index) => (
              //todo carles: in the future, we might want to snapshot every step, and use last snapshot in the slide that will be transitioning out
              // <Item key={sale.id} gemimage={slideSnapshotsRef[index] || sale.story_image || sale.gemimage} onClick={() => {}} />
              <Item key={sale.id} gemimage={sale.story_image || sale.gemimage} onClick={() => {}} />
            ))}
          </Carousel>
        )}

        {loaded && <ItemInfo enabled={true} bottomInfoEnabled={!appState.showGame} key={currentSale.id} type={'live'} onClick={() => {}} gemz={currentSale} />}
      </MintHomeLayout>

      {currentSale && (
        <div className={`story-game ${appState.showGame ? 'enabled' : 'disabled'}`}>
          <HostedApp src={gameUrl} style={{ position: 'absolute' }} />;
        </div>
      )}

      {/* <ModalDrawer
        className="modal-fade"
        onDismiss={onModalClose}
        show={showModal}
        hideClose={true}
        disableBackdrop={false}
        // childrenContainerClassName="center-flex"
        // title={<p className="modal-title">This is the modal title</p>}
        // hideClose
        // disableBackdrop
      >
        {currentSale && <MintPage saleId={currentSale.saleId} saleType={currentSale.saleType} />}
      </ModalDrawer> */}

      {!loaded && <TransitioningSection fixed={true} />}
      {/* todo: we prolly should display a non blocking spinner while current story is actually loading */}
      {/* {viewerReady && !appState.showGame && currentSale && <TransitioningSection />} */}
    </Page>
  );
}

const getFormDataParams = (fields: Record<string, string>, file: File) => {
  const formData = new FormData();
  Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
  formData.append('file', file);
  return {
    method: 'POST',
    body: formData,
  };
};

const uploadFileWithSignedPost = async ({ b64, key }: { b64: string; key: string }) => {
  const res: Response = await fetch(b64);
  const blob: Blob = await res.blob();
  const file = new File([blob], 'image.jpg', { type: 'image/jpg' });

  let signedPostData: any;
  console.log('uploadFileWithSignedPost', { key });
  try {
    const response = await fetch(`https://pipeline.beta.pnk.one/gems/minted/img/${key}`);
    signedPostData = await response.json();
    const uploadResponse = await fetch(signedPostData.url, getFormDataParams(signedPostData.fields, file));
    if (!uploadResponse.ok) {
      throw new Error(uploadResponse.statusText);
    }

    const uploadedImageUrl = `https://media.pnk.one/gemz/${key}`;

    return uploadedImageUrl;
  } catch (e) {
    console.log(`Error (uploadFileWithSignedPost):`, e);
    throw new Error(`Error: Could not sign post for '${key}'.`);
  }
};
