export type DebouncedFn<FnType extends Function> = FnType & { clear: () => void; flush: () => void };

export function debounce<FnType extends Function>(fn: FnType, delay: number, immediate = false): DebouncedFn<FnType> {
  let timeoutId: ReturnType<typeof window.setTimeout> = undefined;
  let called = false;
  let lastArgs: any;

  const debouncedFn = (...args: any) => {
    lastArgs = args;
    if (timeoutId !== undefined) {
      return;
    }

    if (immediate) {
      fn.apply(this, args);
    }

    called = true;
    timeoutId = setTimeout(() => {
      timeoutId = undefined;
      called = false;
      if (!immediate) {
        fn.apply(this, args);
      }
    }, delay);
  };
  debouncedFn.clear = () => clearTimeout(timeoutId);
  debouncedFn.flush = () => {
    if (called) {
      fn.apply(this, lastArgs);
      called = false;
    }
    debouncedFn.clear();
  };

  return debouncedFn as unknown as DebouncedFn<FnType>;
}

export default debounce;
