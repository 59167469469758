import './Item.scss';

export type ItemProps = {
  onClick: () => void;
  gemimage?: string;
};

export function Item({ onClick, gemimage }: ItemProps) {
  function clickItem(e: React.MouseEvent, id: string) {
    e.stopPropagation();
    onClick && onClick();
  }

  return (
    <div className="item-container" onClick={(e) => clickItem(e, '')}>
      {/* {console.error('>>> gemimage', gemimage)} */}
      <div className="item-image" style={{ backgroundImage: `url(${gemimage})` }} />
      {/* <img src={gemimage} style={{ height: '100%' }} /> */}
    </div>
  );
}

export default Item;
