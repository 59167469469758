import { MintPageState, useMintState } from '@context/mint/MintContext';
import { Page } from '../Page';
import CollectionsLayout from './layouts/CollectionsLayout';
import CollectionInfo from './components/CollectionInfo';
import CollectionsForm from './components/CollectionsForm';
import CollectionsFormSuccess from './components/CollectionsFormSuccess';
import { ErrorSection } from './components/ErrorSection';
import CollectionsMint from './components/CollectionsMint';
import { useMemo } from 'react';
import { HeaderTexts } from '@common/Sale';

function useHeaderTexts(): HeaderTexts {
  const { sale, pageState, winner } = useMintState();

  return useMemo(() => {
    let texts = {
      title: sale?.saleHeaderTitle ?? 'Collectible Stories',
      subTitle: sale?.saleHeaderSubTitle ?? 'Dive right into our collectible stories',
    };
    switch (pageState) {
      case MintPageState.PreMint:
        texts = {
          ...texts,
          ...sale?.mintCopy?.upcoming?.header,
        };
        break;
      case MintPageState.Mint:
      case MintPageState.MintEntry:
      case MintPageState.MintNoSupply:
      case MintPageState.MintShare:
      case MintPageState.Minting:
      case MintPageState.Minted:
        if (winner) {
          texts = {
            ...texts,
            ...sale?.mintCopy?.open?.winners?.header,
          };
        } else {
          texts = {
            ...texts,
            ...sale?.mintCopy?.open?.public?.header,
          };
        }
        break;
      case MintPageState.PostMint:
        texts = {
          ...texts,
          ...sale?.mintCopy?.closed?.header,
        };
        break;
    }
    return texts;
  }, [sale, pageState, winner]);
}

function getCollSection(mintPageState: MintPageState) {
  switch (mintPageState) {
    case MintPageState.PreWhitelist:
    case MintPageState.Whitelist:
    case MintPageState.WhitelistChecking:
    case MintPageState.WhitelistNoSpots:
      console.warn('>>> CollectionsForm');
      return <CollectionsForm />;
    case MintPageState.WhitelistChecked:
      console.warn('>>> CollectionsFormSuccess');
      return <CollectionsFormSuccess />;
    case MintPageState.PreMint:
    case MintPageState.Mint:
    case MintPageState.MintEntry:
    case MintPageState.MintNoSupply:
    case MintPageState.MintShare:
    case MintPageState.Minting:
    case MintPageState.Minted:
    case MintPageState.PostMint:
      console.warn('>>> CollectionsMint');
      return <CollectionsMint />;
    default:
      return <ErrorSection>Unexpected page state</ErrorSection>;
  }
}

export function CollectionsPageLayout() {
  const { sale, pageState } = useMintState();
  const content = getCollSection(pageState);
  const { title, subTitle } = useHeaderTexts();
  const analyticsPageName = `collections-${sale.saleId}`;

  return content; // <div className="coll-form">{content}</div>;

  return (
    <div className="coll-layout-columns">
      <div className="coll-layout-column left">{content}</div>
    </div>
  );

  return (
    <Page className="container mb-5" title="Collectible Stories" introBg analyticsPageName={analyticsPageName}>
      <CollectionsLayout className="coll-state-page-layout" title={title} subtitle={subTitle}>
        <div className="coll-layout-columns">
          <div className="coll-layout-column left">{content}</div>
          {/* <div className="coll-layout-column right">
            <CollectionInfo />
          </div> */}
        </div>
      </CollectionsLayout>
    </Page>
  );
}
