import { Spinner } from '@components/spinner/Spinner';

type TransitoningSectionProps = {
  fixed?: boolean;
};

export function TransitioningSection({ fixed }: TransitoningSectionProps) {
  //  py-5
  return (
    <div className={`transitioning-section ${fixed ? 'fixed' : ''}`}>
      <Spinner />
    </div>
  );
}

export default TransitioningSection;
