import './BottomNavBar.scss';

import IconHome from '@assets/_gemz/icons8-home-100.png';
import IconProfile from '@assets/_gemz/icons8-profile-100.png';
import IconCreate from '@assets/_gemz/icons8-create-100.png';
import IconInbox from '@assets/_gemz/icons8-inbox-100.png';
import IconExplore from '@assets/_gemz/icons8-explore-100.png';
import { AppActionType, useAppDispatch } from '@context/AppContext';
import { useNavigate } from 'react-router-dom';
import { Feed } from '../MintHomePage';
// import IconExplore from '@assets/_gemz/icons8-search-100.png';

export type BottomNavBarProps = {};

export function BottomNavBar({}: BottomNavBarProps) {
  const appDispatch = useAppDispatch();

  const navigate = useNavigate();

  return (
    <div className="bottom-navbar">
      <div className="navbar-items">
        <div
          className="navbar-item home"
          onClick={async () => {
            // console.warn('>>> Home click');
            appDispatch({
              type: AppActionType.GameIsPlaying,
              showGame: false,
            });

            if (
              location.href.includes('profile') ||
              location.href.includes('create') ||
              location.href.includes('notification') ||
              location.href.includes('explore')
            ) {
              // todo carles: we should somehow know current feed/items without having to refetch things every time
              const feedResponse = await fetch('https://pipeline.beta.pnk.one/gems/feed/content');
              const feed = (await feedResponse.json()) as Feed[];
              const name = feed[0].gemname;
              const newGemRoute = name.toLowerCase().split(' ').join('_');
              navigate(`/${newGemRoute}`);
            }
          }}
        >
          <div className="navbar-item-icon" style={{ backgroundImage: `url(${IconHome})` }} />
          <div className="navbar-item-label">Home</div>
        </div>

        <div
          className="navbar-item profile"
          onClick={() => {
            // console.warn('>>> Profile click');
          }}
        >
          <div className="navbar-item-icon" style={{ backgroundImage: `url(${IconProfile})` }} />
          <div className="navbar-item-label">Profile</div>
        </div>

        <div
          className="navbar-item create"
          onClick={() => {
            // console.warn('>>> Create click');
            navigate('/create');
          }}
        >
          <div className="navbar-item-icon" style={{ backgroundImage: `url(${IconCreate})` }} />
          <div className="navbar-item-label">Create</div>
        </div>

        <div
          className="navbar-item inbox"
          onClick={() => {
            appDispatch({
              type: AppActionType.GameIsPlaying,
              showGame: false,
            });
            navigate('/notifications');
            // console.warn('>>> Inbox click');
          }}
        >
          <div className="navbar-item-icon" style={{ backgroundImage: `url(${IconInbox})` }} />
          <div className="navbar-item-label">Inbox</div>
        </div>

        <div
          className="navbar-item explore"
          onClick={() => {
            // console.warn('>>> Explore click');
          }}
        >
          <div className="navbar-item-icon" style={{ backgroundImage: `url(${IconExplore})` }} />
          <div className="navbar-item-label">Explore</div>
        </div>
      </div>
    </div>
  );
}

export default BottomNavBar;
