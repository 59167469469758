import './MintHomeLayout.scss';
import { useClassName } from '@hooks/useClassName';
import React from 'react';
import { TextNewLiner } from '@components/text-new-liner/TextNewLiner';
import { GradientTitle } from '@components/GradientTitle';

export type MintHomeLayoutProps = {
  title: string;
  subtitle: string;
  showDates?: boolean;
  children?: React.ReactNode;
  className?: string;
};

export function MintHomeLayout({ className, title, subtitle, children }: MintHomeLayoutProps) {
  const classNames = useClassName('home-layout', className);
  return (
    <div className={classNames}>
      {/* <header className="home-layout-header">
        <div className="home-layout-title-container">
          <GradientTitle className="home-layout-title">{title}</GradientTitle>
         
          <p className="home-layout-subtitle">{subtitle}</p>
        </div>
      </header> */}
      <div className="home-layout-body">{children}</div>
    </div>
  );
}

export default MintHomeLayout;
