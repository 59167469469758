import { Page } from '../Page';
import { useEffect, useState } from 'react';
import { mainSuite } from '@services/ServiceFactory';
import { MintActionType, MintPageState, MintProvider, useMintDispatch, useMintState } from '../../context/mint/MintContext';
import { PremintSubPage } from './PremintSubPage';
import MintSubPage from './MintSubPage';
import TransitioningSection from '@components/transitioning-section';
import { environment } from '@environment';
import { LoadState } from '@common/LoadState';
import './MintPage.scss';
import { useAppState } from '@context/AppContext';
import { SearchParam } from '@common/SearchParam';
import { debug } from '@common/LogWrapper';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Sale from '@common/Sale';
import { SaleState } from '@common/SaleState';
import { PathParam } from '@common/PathParam';
import { fetchSaleState } from '@common/SalePicker';
import { getSaleDateFlagsFromTime, relativeMask, SaleDateFlag, saleKindMask } from '@common/SaleUtils';
import MintSubPageStoryNft from '@src/pages/mint-v2/MintSubPageStoryNft';
import { CollectionsPageLayout } from './CollectionsPageLayout';

const log = debug('app:pages:MintPage');

enum MintForcePage {
  PreWhitelist = 'prewl',
  DuringWhitelist = 'wl',
  PreMint = 'premint',
  DuringMint = 'mint',
  PostMint = 'postmint',
}

const forcePageMap: Record<MintForcePage, MintPageState> = {
  [MintForcePage.PreWhitelist]: MintPageState.PreWhitelist,
  [MintForcePage.DuringWhitelist]: MintPageState.Whitelist,
  [MintForcePage.PreMint]: MintPageState.PreMint,
  [MintForcePage.DuringMint]: MintPageState.Mint,
  [MintForcePage.PostMint]: MintPageState.PostMint,
};

function NoActiveSaleSection() {
  // console.error('>>> NoActiveSaleSection'); //  mb-5
  return (
    <Page title="Mint" className="cocakgentainer">
      {/* <div className="alert alert-info" role="alert"></div> */}
      <div className="mint-alert" role="alert">
        There is no active minting right now.
      </div>
    </Page>
  );
}

function MintTransitionSection() {
  // console.error('>>> MintTransitionSection');
  return (
    <div className="mint-transition-section">
      <TransitioningSection />
    </div>
  );
}

function ErrorSection({ children }: { children?: React.ReactNode }) {
  // console.error('>>> ErrorSection');
  return (
    <Page title="Mint" className="container">
      {/* <div className="alert alert-danger" role="alert"> */}
      <div className="mint-alert" role="alert">
        {children}
      </div>
    </Page>
  );
}

const saleDateFlagsToPageState: Partial<Record<SaleDateFlag, MintPageState>> = {
  [SaleDateFlag.BeforeWhitelistSignUp]: MintPageState.PreWhitelist,
  [SaleDateFlag.DuringWhitelistSignUp]: MintPageState.Whitelist,
  [SaleDateFlag.AfterWhitelistSignUp]: MintPageState.WhitelistNoSpots,
  [SaleDateFlag.BeforeWhitelistMint]: MintPageState.PreMint,
  [SaleDateFlag.DuringWhitelistMint]: MintPageState.Mint,
  [SaleDateFlag.AfterWhitelistMint]: MintPageState.PostMint,
  [SaleDateFlag.BeforePublicMint]: MintPageState.PreMint,
  [SaleDateFlag.DuringPublicMint]: MintPageState.Mint,
  [SaleDateFlag.AfterPublicMint]: MintPageState.PostMint,
};

const loadStateMap: Record<LoadState, JSX.Element | null> = {
  [LoadState.Idle]: <MintTransitionSection />,
  [LoadState.Loading]: <MintTransitionSection />,
  [LoadState.Errored]: null,
  [LoadState.Loaded]: null,
};

const saleTypePageMap: Record<string, Record<MintPageState, JSX.Element | null>> = {
  characterPass: {
    [MintPageState.Idle]: <MintTransitionSection />,
    [MintPageState.NoSale]: <NoActiveSaleSection />,
    [MintPageState.PreWhitelist]: <NoActiveSaleSection />,
    [MintPageState.Whitelist]: <PremintSubPage />,
    [MintPageState.WhitelistChecking]: <PremintSubPage />,
    [MintPageState.WhitelistChecked]: <PremintSubPage />,
    [MintPageState.WhitelistNoSpots]: <PremintSubPage />,
    [MintPageState.PreMint]: <PremintSubPage />,
    [MintPageState.Mint]: <MintSubPage />,
    [MintPageState.MintEntry]: <MintSubPage />,
    [MintPageState.MintShare]: <MintSubPage />,
    [MintPageState.Minting]: <MintSubPage />,
    [MintPageState.Minted]: <MintSubPage />,
    [MintPageState.MintNoSupply]: <MintSubPage />,
    [MintPageState.PostMint]: <MintSubPage />,
    [MintPageState.Errored]: null,
  },
  collection: {
    [MintPageState.Idle]: <MintTransitionSection />,
    [MintPageState.NoSale]: <NoActiveSaleSection />,
    [MintPageState.PreWhitelist]: <NoActiveSaleSection />,
    [MintPageState.Whitelist]: <CollectionsPageLayout />,
    [MintPageState.WhitelistChecking]: <CollectionsPageLayout />,
    [MintPageState.WhitelistChecked]: <CollectionsPageLayout />,
    [MintPageState.WhitelistNoSpots]: <CollectionsPageLayout />,
    [MintPageState.PreMint]: <CollectionsPageLayout />,
    [MintPageState.Mint]: <CollectionsPageLayout />,
    [MintPageState.MintEntry]: <CollectionsPageLayout />,
    [MintPageState.MintShare]: <CollectionsPageLayout />,
    [MintPageState.Minting]: <CollectionsPageLayout />,
    [MintPageState.Minted]: <CollectionsPageLayout />,
    [MintPageState.MintNoSupply]: <CollectionsPageLayout />,
    [MintPageState.PostMint]: <CollectionsPageLayout />,
    [MintPageState.Errored]: null,
  },
};

export function MintPage({ enabled, saleId, saleType }: { enabled?: boolean; saleId?: string; saleType?: string }) {
  if (enabled === undefined) {
    enabled = true;
  }

  const appState = useAppState();
  const { saleService, analyticsService } = mainSuite;
  const mintState = useMintState();
  const mintDispatch = useMintDispatch();
  const [error, setError] = useState<string>(null);
  const pathParams = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  console.warn('>>> MintPage', saleId, saleType);

  useEffect(() => {
    const usingSaleId = saleId ?? pathParams?.[PathParam.SaleId];
    const cachedId = mintState?.sale?.saleId;
    if (cachedId && cachedId !== usingSaleId) {
      mintDispatch({
        type: MintActionType.UpdateLoadState,
        loadState: LoadState.Idle,
      });
    }
    analyticsService.setPersistedOptions({
      saleId: mintState?.sale?.saleId,
      tokenType: mintState?.sale?.tokenType || undefined, // convert empty strings to undefined
    });
    analyticsService.userTrack({
      tokenType: mintState?.sale?.tokenType || undefined, // convert empty strings to undefined
    });

    return () => {
      analyticsService.setPersistedOptions({
        saleId: undefined,
        tokenType: undefined,
      });
      analyticsService.userTrack({
        tokenType: undefined, // convert empty strings to undefined
      });
    };
  }, [mintState?.sale, pathParams?.[PathParam.SaleId]]);

  // load whitelist/mint info
  useEffect(() => {
    if (mintState.loadState !== LoadState.Idle) {
      return;
    }

    async function fetchSale(): Promise<{ sale: Sale; saleState: SaleState }> {
      let saleFromListing = await saleService.fetchSaleBy({ listingUri: pathParams?.[PathParam.RaffleURI] });
      const saleIdFromParam = saleFromListing.saleId || pathParams?.[PathParam.SaleId];
      const usingSaleId = saleId ?? saleIdFromParam;
      if (usingSaleId) {
        const sale = await saleService.fetchSale(usingSaleId.toLowerCase());
        if (!sale) {
          return { sale, saleState: SaleState.None };
        }
        const saleState = await fetchSaleState(sale, saleService);
        return { sale, saleState };
      } else {
        return await saleService.fetchCurrentSale(saleType);
      }
    }

    async function loadInfo() {
      const { sale, saleState } = await fetchSale();

      mintDispatch({
        type: MintActionType.UpdateSale,
        sale,
        saleState,
      });

      mintDispatch({
        type: MintActionType.UpdateLoadState,
        loadState: LoadState.Loaded,
      });
    }

    loadInfo().catch((e) => {
      log('Error occurred while loading info:', e);
      mintDispatch({
        type: MintActionType.UpdateLoadState,
        loadState: LoadState.Errored,
      });
    });
  }, [mintState.loadState, pathParams, saleType]);

  // determine which page we should be on
  useEffect(() => {
    if (mintState.loadState !== LoadState.Loaded) {
      return;
    }
    if (mintState.pageState !== MintPageState.Idle) {
      return;
    }

    if (!mintState.sale || !mintState.saleState) {
      mintDispatch({
        type: MintActionType.UpdateMintPageState,
        pageState: MintPageState.NoSale,
      });
      return;
    }

    if (environment.allowForcePage && appState.initialSearchParams.has(SearchParam.ForcePage)) {
      const forcePage = appState.initialSearchParams.get(SearchParam.ForcePage) as MintForcePage;
      const pageState = forcePageMap[forcePage] ?? MintPageState.Idle;
      if (pageState !== MintPageState.Idle) {
        mintDispatch({
          type: MintActionType.UpdateMintPageState,
          pageState,
          forced: true,
        });
        return;
      }
    }

    const saleDateFlags = getSaleDateFlagsFromTime(mintState.sale);
    let pageState = saleDateFlagsToPageState[saleDateFlags];
    if (pageState === MintPageState.Whitelist && mintState.saleState === SaleState.OutOfSpots) {
      pageState = MintPageState.WhitelistNoSpots;
    } else if (pageState === MintPageState.Mint && mintState.saleState === SaleState.OutOfSupply) {
      pageState = MintPageState.MintNoSupply;
    }

    mintDispatch({
      type: MintActionType.UpdateMintPageState,
      pageState,
    });
  }, [mintState.loadState, mintState.pageState, mintState.sale, mintState.saleState, appState.initialSearchParams]);

  // timer to automatically switch subpages
  useEffect(() => {
    if (mintState.forcedPage) {
      // don't auto advance if page was forced
      return;
    }
    if (mintState.loadState !== LoadState.Loaded) {
      return;
    }

    if (mintState.pageState === MintPageState.Idle) {
      return;
    }

    if (!mintState.sale) {
      // reset and redirect to homepage
      mintDispatch({
        type: MintActionType.Reset,
      });
      navigate('/');
      return;
    }

    const saleDateFlags = getSaleDateFlagsFromTime(mintState.sale);
    const saleKind = saleDateFlags & saleKindMask;

    let targetTime: number;
    let targetPageState: MintPageState = MintPageState.Idle;
    switch (mintState.pageState) {
      case MintPageState.PreWhitelist:
        if (mintState.sale?.whitelist?.signUp?.startDate) {
          targetTime = new Date(mintState.sale.whitelist.signUp.startDate).getTime();
          targetPageState = MintPageState.Whitelist;
        }
        break;
      case MintPageState.Whitelist:
      case MintPageState.WhitelistChecking:
      case MintPageState.WhitelistChecked:
      case MintPageState.WhitelistNoSpots:
        if (mintState.sale?.whitelist?.signUp?.endDate) {
          targetTime = new Date(mintState.sale.whitelist.signUp.endDate).getTime();
          targetPageState = MintPageState.PreMint;
        }
        break;
      case MintPageState.PreMint:
        if (saleKind === SaleDateFlag.WhitelistMint && mintState.sale?.whitelist?.mint?.startDate) {
          targetTime = new Date(mintState.sale.whitelist.mint.startDate).getTime();
          targetPageState = MintPageState.Mint;
        } else if (saleKind === SaleDateFlag.PublicMint && mintState.sale?.publicMint?.startDate) {
          targetTime = new Date(mintState.sale.publicMint.startDate).getTime();
          targetPageState = MintPageState.Mint;
        }
        break;
      case MintPageState.Mint:
      case MintPageState.MintEntry:
      case MintPageState.MintShare:
      case MintPageState.Minting:
      case MintPageState.Minted:
      case MintPageState.MintNoSupply:
        if (saleKind === SaleDateFlag.WhitelistMint && mintState.sale?.whitelist?.mint?.endDate) {
          targetTime = new Date(mintState.sale.whitelist.mint.endDate).getTime();
          targetPageState = MintPageState.PostMint;
        } else if (saleKind === SaleDateFlag.PublicMint && mintState.sale?.publicMint?.endDate) {
          targetTime = new Date(mintState.sale.publicMint.endDate).getTime();
          targetPageState = MintPageState.PostMint;
          log('state 1', targetTime);
        }
        break;
    }
    if (targetPageState === MintPageState.Idle) {
      return;
    }

    /*
    We're assuming the page state has been set correctly according to the
    dates. But in case we're somehow in the wrong state and the target time
    is before now, we'll just go to the target page state directly.
    */
    const timeLeft = targetTime - Date.now();
    if (timeLeft < 0) {
      log('shoud not see this');
      mintDispatch({
        type: MintActionType.UpdateMintPageState,
        pageState: targetPageState,
      });
      return;
    }
    let timeoutId;
    if (timeLeft < 604800) {
      timeoutId = setTimeout(() => {
        log('really should not see this');
        mintDispatch({
          type: MintActionType.UpdateMintPageState,
          pageState: targetPageState,
        });
      }, timeLeft);
    } else {
      timeoutId = setTimeout(() => {}, 0);
    }

    return () => clearTimeout(timeoutId);
  }, [mintState.loadState, mintState.pageState, mintState.forcedPage, mintState.sale]);

  let content: JSX.Element | null = loadStateMap[mintState.loadState];
  if (!content) {
    if (mintState.loadState === LoadState.Errored) {
      content = <ErrorSection>{error ?? 'Unknown load error has occurred.'}</ErrorSection>;
    } else {
      const pageMap = saleTypePageMap[mintState.sale?.saleType];
      if (!pageMap) {
        content = <ErrorSection>Unknown sale type.</ErrorSection>;
      } else {
        content = pageMap[mintState.pageState];
      }
      if (!content) {
        content = <ErrorSection>{error ?? 'Unknown error has occurred.'}</ErrorSection>;
      }
    }
  }

  console.warn('>>> mintState', mintState);
  // if (mintState && mintState.sale && mintState.pageState) {
  //   const pageMap = saleTypePageMap[mintState.sale?.saleType][mintState.pageState];
  //   console.warn('>>> pageMap', mintState.pageState, pageMap.type.name, pageMap);
  // }
  console.warn('>>> content', mintState.pageState, content.type.name, content);

  return enabled && content;
}

export default MintPage;
