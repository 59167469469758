import './CollectionsForm.scss';
import { debug } from '@common/LogWrapper';
import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { mainSuite } from '@services/ServiceFactory';
import { IsSubscribedTwitterStatus, SubscribeOpts } from '@services/emailsub/EmailSubService';
import { MintActionType, MintPageState, useMintDispatch, useMintState } from '@context/mint/MintContext';
import Sale, { SaleFollowSocial, SaleSocialType } from '@common/Sale';
import { Follow } from 'react-twitter-widgets';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Modal } from '@components/modal/Modal';
import { Spinner } from '@components/spinner/Spinner';
import React from 'react';
import { environment } from '@environment';
import { StepBox } from './StepBox';
import { ArrowIcon } from './ArrowIcon';
import { AnalyticsEventName } from '@services/analytics/AnalyticsEventName';
import { useAppState } from '@context/AppContext';
import { SearchParam } from '@common/SearchParam';
import { AlreadySubbedError } from '@errors';
import { useUserHook } from '@hooks/useUserHook';
import { SvsProvider, TwtAuthVersion } from '@storyverseco/svs-navbar';
import { truncateAddress } from '@common/StringUtils';
import { ModalDrawer } from '@components/modaldrawer/ModalDrawer';

const log = debug('app:pages:collections:components:CollectionsForm');

export type CollectionsFormProps = {};

enum SubscribeState {
  Idle,
  Checking,
  NotSubbed,
  Subbed,
}

const alreadyRegisteredStatuses = [IsSubscribedTwitterStatus.NotMatching, IsSubscribedTwitterStatus.Matching];

function FollowTwitterLinks({ followSocials }: { followSocials: Sale['followSocials'] }) {
  if (!followSocials) {
    return null;
  }

  return (
    <>
      <p className="inter" style={{ marginBottom: 31 }}>
        Follow us on Twitter to be eligible
      </p>
      <div className="coll-form-follow-links">
        {followSocials
          .filter((social) => social.type === SaleSocialType.Twitter)
          .map((social, index, arr) => (
            <div key={`${social.type}-${social.userId}`}>
              <Follow username={social.userId} options={{ size: 'large' }} />
            </div>
          ))}
      </div>
    </>
  );
}

const getTwitterStepTitle = (followSocials: SaleFollowSocial[]) => (
  <>
    Follow,{' '}
    {followSocials
      .filter((social) => social.type === SaleSocialType.Twitter)
      .map((social) => (
        <React.Fragment key={`step-title-${social.type}-${social.userId}`}>
          <a href={`https://twitter.com/${social.userId}`} target="_blank">
            {social.userId || ''}
          </a>{' '}
        </React.Fragment>
      ))}
  </>
);

export function CollectionsForm({}: CollectionsFormProps) {
  console.error('>>> CollectionsForm');

  // const classNames = useClassName('coll-form', className);
  const mintState = useMintState();
  const mintDispatch = useMintDispatch();
  const { emailSubService, analyticsService, chainalysisService, navbarService } = mainSuite;
  const { twitterService } = navbarService.api;
  const [errorMsg, setErrorMsg] = useState(null);
  const [subscribeState, setSubscribeState] = useState(SubscribeState.Idle);
  const [submitting, setSubmitting] = useState(false);
  const [showCaptchaModal, setShowCaptchaModal] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [twitterVerifying, setTwitterVerifying] = useState(false);
  const [triedVerifyOnce, setTriedVerifyOnce] = useState(false);
  const [remoteTwStatus, setRemoteTwStatus] = useState<string | null>(null);

  const { wallet, error: walletConnectError, ready, logIn, initted, providerReady } = useUserHook({ providerType: SvsProvider.WalletConnect });

  const isConnected = ready && Boolean(wallet?.flags.loggedIn);
  const isError = Boolean(walletConnectError);
  const address = isConnected ? wallet?.address : null;

  const walletBoxTitle = address ? truncateAddress(address) : 'Connect your wallet';
  const walletBoxSubtitle = isConnected ? 'Wallet Connected' : 'Sign up for the giveaway';

  const [isTwitterVerified, setIsTwitterVerified] = useState(false);

  const submitBtnDisabled = useMemo(() => !isConnected || !isTwitterVerified || submitting, [isConnected, isTwitterVerified, submitting]);

  function onSubmit(e: FormEvent) {
    e.preventDefault();
    analyticsService.track(AnalyticsEventName.ButtonPress, {
      buttonName: 'submit',
      saleId: mintState.sale?.saleId,
    });
    setSubmitting(true);
    if (environment.envName === 'mock') {
      setCaptchaToken('mock');
    } else {
      setCaptchaToken(null);
    }
    setShowCaptchaModal(true);
  }

  function reportError(opts: { message: string; stepId: string }) {
    setErrorMsg(opts.message);
    analyticsService.track(AnalyticsEventName.GenericError, {
      stepId: opts.stepId,
      saleId: mintState.sale?.saleId,
    });
  }

  useEffect(() => {
    if (isError && walletConnectError) {
      reportError({
        message: walletConnectError.message,
        stepId: 'wallet-connect',
      });
    }
  }, [isError]);

  // After the user has gone through captcha, subscribe
  useEffect(() => {
    if (captchaToken) {
      const subscribeBody: SubscribeOpts = {
        campaign: mintState.sale?.marketingCampaign,
        socialHandles: {
          hotWallet: address,
        },
        captchaToken,
      };

      chainalysisService
        .isWalletAddressSafe(address)
        .then((isSafe) => {
          if (!isSafe) {
            throw new Error('This wallet is blocked.');
          }

          return twitterService.auth
            .get()
            .then((user) => {
              if (user?.handle) {
                subscribeBody.socialHandles.twitterHandle = user.handle;
              } else {
                throw new Error('Missing twitter username from auth.get()');
              }
            })
            .then(() => emailSubService.subscribe(subscribeBody))
            .then((results) => {
              if (!results.socials) {
                reportError({
                  message: 'Invalid social handles. Please correct and try again.',
                  stepId: 'no-socials',
                });
                return;
              }
              analyticsService.track(AnalyticsEventName.SignupSuccess, {
                pageName: `collections-${mintState.sale?.saleId}`,
              });
              mintDispatch({
                type: MintActionType.UpdateMintPageState,
                pageState: MintPageState.WhitelistChecked,
              });
            })
            .catch((e) => {
              if (AlreadySubbedError.isError(e)) {
                log('Already subscribed:', e);
                reportError({
                  message: 'This wallet or Twitter account was already entered.',
                  stepId: 'already-subscribed',
                });
                return;
              }
              log('Error occurred during collections signup form ', e);
              reportError({
                message: 'Error occurred while signing up',
                stepId: 'signup-form-error',
              });
            });
        })
        .catch((e) => {
          reportError({
            message: e.message || '',
            stepId: 'chainalysis-error',
          });
        })
        .finally(() => {
          setShowCaptchaModal(false);
          setSubmitting(false);
        });
    }
  }, [captchaToken]);

  // check for subscribed status
  useEffect(() => {
    if (!isConnected) {
      return;
    }
    if (subscribeState !== SubscribeState.Idle) {
      return;
    }

    twitterService.auth
      .get()
      .then((user) => {
        if (!user?.handle) {
          return emailSubService.isSubscribed({
            campaign: mintState.sale?.marketingCampaign,
            walletAddress: address,
          });
        }

        return emailSubService.isSubscribed({
          campaign: mintState.sale?.marketingCampaign,
          walletAddress: address,
          twitterHandle: user.handle,
        });
      })
      .then((result) => {
        setSubscribeState(result.exists ? SubscribeState.Subbed : SubscribeState.NotSubbed);
        if ('twitterStatus' in result) {
          setRemoteTwStatus(result.twitterStatus);
        }
      })
      .catch((e) => {
        log('Error occurred while checking if subscribed', e);
        reportError({
          message: 'Error occurred while checking if subscribed',
          stepId: 'check-subscribed-error',
        });
      });
  }, [isConnected, address, subscribeState]);

  // if subscribed, automatically go to "congrats" section
  useEffect(() => {
    if (subscribeState === SubscribeState.Subbed) {
      mintDispatch({
        type: MintActionType.UpdateMintPageState,
        pageState: MintPageState.WhitelistChecked,
      });
    }
  }, [subscribeState]);

  const onVerifyCaptcha = (token: string) => {
    setCaptchaToken(token);
    analyticsService.track(AnalyticsEventName.ButtonPress, {
      buttonName: 'captchaVerified',
      saleId: mintState.sale?.saleId,
    });
  };

  const onModalClose = useCallback(() => {
    setShowCaptchaModal(false);
  }, []);

  const onTwitterVerify = useCallback(async () => {
    if (isTwitterVerified) {
      return;
    }

    setTwitterVerifying(true);
    const verifyingId = setTimeout(() => {
      setTwitterVerifying(false);
    }, 5000);
    analyticsService.track(AnalyticsEventName.ButtonPress, {
      buttonName: 'twitterVerify',
      saleId: mintState.sale?.saleId,
    });
    setErrorMsg('');

    // need to check if we have permissions first
    let loggedIn = await twitterService.auth.canRead();
    if (!loggedIn) {
      // technically this will await forever, but we'll pretend it returns a user (or null)
      const user = await twitterService.auth.logIn({ authVersion: TwtAuthVersion.V1Write });
      loggedIn = Boolean(user);
    }

    if (!loggedIn) {
      // should not reach here but just in case
      // we're making an error here for sentry to pick up through the wrapped debug log
      const error = new Error('twitterService auth logIn somehow returned no user during a logged out session');
      log('unexpected twitter service state:', error);
      reportError({
        message: 'Needs Twitter authorization.',
        stepId: 'twitter-verify-auth-error',
      });
      return;
    }

    // get twitter usernames from sale
    const userNames =
      mintState.sale?.followSocials
        ?.filter((social) => social.type === SaleSocialType.Twitter && social.required)
        .map((social) => social.userId.toLowerCase()) ?? [];
    setTriedVerifyOnce(true);
    try {
      // if logged in and wallet connected, check if twitter account is already in raffle
      // if (isConnected) {
      //   const twitterUser = await navbarSuite.twitterService.fetchUser();
      //   if (twitterUser) {
      //     const result = await emailSubService.isSubscribed({
      //       campaign: mintState?.sale?.marketingCampaign,
      //       walletAddress: address,
      //       twitterHandle: twitterUser.userName,
      //     });
      //     if (alreadyRegisteredStatuses.includes(result.twitterStatus)) {
      //       throw new Error('Already entered with that Twitter account.');
      //     }
      //   }
      // }

      const followMap = await twitterService.fetchIsFollowingAll({ userNames });
      const followMapLc: Record<string, boolean> = Object.entries(followMap).reduce(
        (map, [key, value]) => ({
          ...map,
          [key.toLowerCase()]: value,
        }),
        {},
      );
      if (!userNames.every((user) => followMapLc[user])) {
        throw new Error(`Follow all ${userNames.length} Twitter accounts to register.`);
      }
      setIsTwitterVerified(true);
    } catch (e) {
      setIsTwitterVerified(true); // set false later. this is to disable while twitter has our and am illion other apps broken
      // https://twittercommunity.com/t/v2-suddenly-getting-client-not-enrolled-today/195456/63
      // also eat the error for now so as to not spook the normies
      // reportError({
      //   message: e.message,
      //   stepId: 'twitter-verify-error',
      // });
    } finally {
      setTwitterVerifying(false);
      clearTimeout(verifyingId);
    }
  }, [isTwitterVerified, triedVerifyOnce, mintState]);

  // if twitter auth param was present, we should auto verify twitter followers
  useEffect(() => {
    if (isTwitterVerified) {
      return;
    }

    if (triedVerifyOnce) {
      return;
    }

    if (!isConnected) {
      return;
    }

    // only if recently logged in
    twitterService.auth
      .recentlyLoggedIn()
      .then((recentlyLoggedIn) => {
        if (recentlyLoggedIn) {
          return twitterService.auth.canRead();
        } else {
          return false;
        }
      })
      .then((loggedIn) => {
        if (loggedIn) {
          log('auto twitter verify');
          return onTwitterVerify();
        }
      });
  }, [isConnected, isTwitterVerified, onTwitterVerify, triedVerifyOnce]);

  // todo(carles): here we are rendering several stepboxes.
  // todo: instead, we need to render only the current step

  console.error('>>> CollectionsForm init - ', { isConnected, isTwitterVerified, triedVerifyOnce, submitBtnDisabled });

  return (
    <div className="coll-form">
      <div className="coll-form-container">
        {console.error('>>> CollectionsForm', { isConnected, isTwitterVerified, triedVerifyOnce, submitBtnDisabled })}

        {/* connect */}
        <StepBox
          disabled={isConnected} // todo carles
          btn={{
            show: true,
            disabled: isConnected,
            label: isConnected ? 'CONNECTED' : 'CONNECT',
            showArrow: !isConnected,
            onClick: () => {
              logIn();
            },
          }}
          connected={isConnected}
          title={walletBoxTitle}
          subTitle={walletBoxSubtitle}
          icon="wallet"
          iconColor="#D4D2ED"
          done={isConnected}
        />

        {/* verify twitter */}
        <StepBox
          // disabled={!isConnected}
          disabled={!isConnected || (isTwitterVerified && !twitterVerifying)}
          btn={{
            show: true,
            disabled: (isTwitterVerified && isConnected) || twitterVerifying,
            label: twitterVerifying ? 'VERIFYING...' : isTwitterVerified ? 'VERIFIED' : 'VERIFY',
            showArrow: !isTwitterVerified,
            onClick: onTwitterVerify,
          }}
          title={getTwitterStepTitle(mintState.sale?.followSocials.filter((social) => social.type === SaleSocialType.Twitter && social.required))}
          subTitle={isTwitterVerified ? 'Verified' : 'Connect your Twitter'}
          icon={'twitter'}
          done={isTwitterVerified}
        />

        {errorMsg && <div className="coll-form-error-msg">{errorMsg}</div>}

        {/* <StepBox
          // disabled={!isConnected}
          disabled={submitBtnDisabled}
          btn={{
            show: true,
            disabled: (isTwitterVerified && isConnected) || twitterVerifying,
            label: submitting ? 'Submitting...' : 'SUBMIT',
            showArrow: true,
            onClick: onSubmit,
          }}
          title={null} 
          subTitle={null}
          icon={null}
          done={false}
        /> */}

        {/* submit button */}
        <button type="submit" className="mint-btn-cta coll-form-submit-btn" aria-label="Submit" disabled={submitBtnDisabled} onClick={onSubmit}>
          {submitting ? 'Submitting...' : 'SUBMIT'}{' '}
          <span className="arrow-wrapper">
            <ArrowIcon />
          </span>
        </button>

        {showCaptchaModal && (
          <div className="step-container" style={{ margin: '0 auto', marginTop: '10px' }}>
            {captchaToken ? <Spinner spinnerClass="large-spinner" /> : <HCaptcha sitekey={environment.hcaptchaSiteKey} onVerify={onVerifyCaptcha} />}
          </div>
        )}
      </div>

      {/* Carles: This is human verification. we need to restyle it in a way that works.} */}
      {/* change from Modal to ModalDrawer already doing a good job */}
      {/* <ModalDrawer
        onDismiss={onModalClose}
        show={showCaptchaModal}
        childrenContainerClassName="center-flex"
        title={<p className="modal-title">{captchaToken ? 'Registration in progress' : 'Prove you are real'}</p>}
        className="modal-fade"
        hideClose
        disableBackdrop
      >
        {captchaToken ? <Spinner spinnerClass="large-spinner" /> : <HCaptcha sitekey={environment.hcaptchaSiteKey} onVerify={onVerifyCaptcha} />}
      </ModalDrawer> */}
    </div>
  );
}

export default CollectionsForm;

{
  /* <p className="inter">Register for a chance to win</p>
      <ConnectBox  />
      <div className="coll-box-link-container">
        <FollowTwitterLinks followSocials={mintState.sale?.followSocials} />
        <br />
        <button className={`svs-btn connect-btn ${isTwitterVerified ? 'success no-mouse' : ''}`} onClick={onTwitterVerify}>
          {isTwitterVerified ? 'Twitter verified!' : 'Verify with Twitter'}
        </button>
      </div> */
}
