import { Collection, collections } from '@assets/homepage';
import { useMintState } from '@context/mint/MintContext';
import { useUserHook } from '@hooks/useUserHook';
import { mainSuite } from '@services/ServiceFactory';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { SvsNavbarEvent, SvsProvider, Wallet } from '@storyverseco/svs-navbar';
import { WalletAddress, walletAddress } from '@storyverseco/svs-types';

import './MintBox.scss';
import { AnalyticsEventName } from '@services/analytics/AnalyticsEventName';
import { Spinner } from '@components/spinner/Spinner';
import { pipelineApiCall, PipelineEndpoint } from '@common/SvsRestApi';
import { MintBoxFootnote } from './MintBoxFootnote';
import { debug } from '@common/LogWrapper';
import { getMintErrorReason } from '../../common/GetMintErrorReason';

const log = debug('app:components:MintBox');

const defaultAvatar = 'https://i.stack.imgur.com/34AD2.jpg';

const MAX_INPUT = 50;

enum MintStep {
  Idle,
  Signing,
  Minting,
  WaitingForToken,
  Done,
}

const buttonCopy: Record<MintStep, string> = {
  [MintStep.Idle]: 'Mint 0.0505 ETH',
  [MintStep.Signing]: 'Signing', //... elipsis added in component
  [MintStep.Minting]: 'Minting', //... elipsis added in component
  [MintStep.WaitingForToken]: 'Creating story', //... elipsis added in component
  [MintStep.Done]: 'Done!',
};

interface Props {
  onMintStart?: () => void;
  onMintSuccess: (tokenId: string) => void;
  onMintFailure: (
    err: Error,
    data: {
      ErrorName: string;
      ErrorMessage: string;
      Message: string;
    },
  ) => void;
  resetOnSuccess?: boolean;
  skipAwaitToken?: boolean;
}

const useMintStep = ({
  skipAwaitToken,
  onMintSuccess,
  onMintFailure,
  wallet,
  resetOnSuccess,
}: {
  skipAwaitToken: boolean;
  onMintSuccess: Props['onMintSuccess'];
  onMintFailure: Props['onMintFailure'];
  wallet: Wallet;
  resetOnSuccess: Props['resetOnSuccess'];
}) => {
  const mintState = useMintState();
  const [mintStep, setMintStep] = useState(MintStep.Idle);
  const [storyToken, setStoryToken] = useState<string>(null);

  const failMint = useCallback(
    (message: string, rawMessage: string, { error, reason, displayMessage }: { error: string; reason: string; displayMessage: string }) => {
      mainSuite.analyticsService.track(AnalyticsEventName.MintError, {
        Reason: reason,
        Details: JSON.stringify({
          ErrorName: message,
          ErrorMessage: error,
          Message: rawMessage,
        }),
      });
      onMintFailure(new Error(displayMessage), {
        ErrorName: message,
        ErrorMessage: error,
        Message: rawMessage,
      });
      setTimeout(() => {
        setMintStep(MintStep.Idle);
      }, 500);
    },
    [],
  );

  useEffect(() => {
    const { navbarService } = mainSuite;

    const createErrorHandler = (eventName: string) => (e: any) => {
      log(`mint from ${eventName} error:`, e);

      // Finish sentence with a '.'
      const normalisedReason = getMintErrorReason(e);

      const displayMessage = `${normalisedReason} Please try again.`;

      failMint(`Error: Failed to mint`, `Current wallet address: ${wallet.address}\nError name: ${e.name}\nMessage: ${e.message}`, {
        error: e.message,
        displayMessage,
        reason: normalisedReason,
      });
    };

    const onPresignStarted = () => {
      setMintStep(MintStep.Signing);
    };

    const onPresignError = createErrorHandler('presign');

    const onTxCreateError = createErrorHandler('txcreate');

    const onMintStarted = () => {
      setMintStep(MintStep.Minting);
    };

    const onMintError = createErrorHandler('mint');

    const onTxWaitStarted = () => {
      if (skipAwaitToken) {
        setMintStep(MintStep.Done);
      } else {
        setMintStep(MintStep.WaitingForToken);
      }
    };

    const onTxWaitError = createErrorHandler('txwait');

    const onTokenWaitEnded = ({ token }) => {
      setStoryToken(token);
      if (!skipAwaitToken) {
        setMintStep(MintStep.Done);
      }
    };

    const onTokenWaitError = createErrorHandler('tokenwait');

    navbarService.addListener(SvsNavbarEvent.MintPresignStarted, onPresignStarted);
    navbarService.addListener(SvsNavbarEvent.MintPresignError, onPresignError);
    navbarService.addListener(SvsNavbarEvent.MintTxCreateError, onTxCreateError);
    navbarService.addListener(SvsNavbarEvent.MintStarted, onMintStarted);
    navbarService.addListener(SvsNavbarEvent.MintError, onMintError);
    navbarService.addListener(SvsNavbarEvent.MintTxWaitStarted, onTxWaitStarted);
    navbarService.addListener(SvsNavbarEvent.MintTxWaitError, onTxWaitError);
    navbarService.addListener(SvsNavbarEvent.MintTokenWaitEnded, onTokenWaitEnded);
    navbarService.addListener(SvsNavbarEvent.MintTokenWaitError, onTokenWaitError);

    return () => {
      navbarService.removeListener(SvsNavbarEvent.MintPresignStarted, onPresignStarted);
      navbarService.removeListener(SvsNavbarEvent.MintPresignError, onPresignError);
      navbarService.removeListener(SvsNavbarEvent.MintTxCreateError, onTxCreateError);
      navbarService.removeListener(SvsNavbarEvent.MintStarted, onMintStarted);
      navbarService.removeListener(SvsNavbarEvent.MintError, onMintError);
      navbarService.removeListener(SvsNavbarEvent.MintTxWaitStarted, onTxWaitStarted);
      navbarService.removeListener(SvsNavbarEvent.MintTxWaitError, onTxWaitError);
      navbarService.removeListener(SvsNavbarEvent.MintTokenWaitEnded, onTokenWaitEnded);
      navbarService.removeListener(SvsNavbarEvent.MintTokenWaitError, onTokenWaitError);
    };
  }, [setMintStep, failMint, wallet]);

  // On mint complete, fire callback
  useEffect(() => {
    if (mintStep !== MintStep.Done) {
      return;
    }
    mainSuite.analyticsService.track(AnalyticsEventName.MintSuccess, {
      saleId: mintState.sale?.saleId,
      tokenType: mintState.sale?.tokenType,
      walletAddress: wallet.address,
      tokenId: storyToken,
    });
    // Call on a different frame to make sure the analytics event is fired off
    setTimeout(() => {
      onMintSuccess(storyToken);

      if (resetOnSuccess) {
        setMintStep(MintStep.Idle);
      }
    });
  }, [mintStep, storyToken, resetOnSuccess, mintState.sale]);

  const mint = useCallback(
    (opts: { saleId: string; walletAddress: WalletAddress; tokenQuantity?: number }) => mainSuite.saleService.publicSignAndMint(opts),
    [],
  );

  return {
    mintStep,
    mint,
    storyToken,
  };
};

export const MintBox = ({ onMintStart, onMintSuccess, onMintFailure, resetOnSuccess, skipAwaitToken }: Props) => {
  const mintState = useMintState();

  const { wallet } = useUserHook({ providerType: SvsProvider.WalletConnect });

  const { mintStep, mint } = useMintStep({ skipAwaitToken, onMintSuccess, onMintFailure, wallet, resetOnSuccess });

  // Local state
  const [selectedAmount, setSelectedAmout] = useState<number>(1);

  const [isCustomAmount, setIsCustomAmount] = useState(false);

  const [customAmount, setCustomAmount] = useState(1);

  const [tokenQuantity, setTokenQuantity] = useState(1);

  const [ethEstimate, setEthEstimate] = useState<number>();

  const onAmountClick = (amount: number) => () => {
    setIsCustomAmount(false);
    setSelectedAmout(amount);
    setTokenQuantity(amount);
  };

  const onCustomAmountClick = useCallback(() => {
    setSelectedAmout(undefined);
    setIsCustomAmount(true);
    mainSuite.analyticsService.track(AnalyticsEventName.ButtonPress, {
      buttonName: 'custom_token_amount',
    });
  }, []);

  const onMinusClick = useCallback(() => {
    if (customAmount <= 1) {
      return;
    }
    const value = customAmount - 1;
    setCustomAmount(value);
    setTokenQuantity(value);
  }, [customAmount]);

  const onPlusClick = useCallback(() => {
    if (customAmount >= MAX_INPUT) {
      setCustomAmount(MAX_INPUT);
      return;
    }
    const value = customAmount + 1;
    setCustomAmount(value);
    setTokenQuantity(value);
  }, [customAmount]);

  const onCustomAmountInputChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;

    const numVal = Number(value);

    // Add 'numVal > 50' to some config
    if (Number.isNaN(numVal) || numVal < 0 || numVal > MAX_INPUT) {
      return;
    }

    setCustomAmount(numVal);
    setTokenQuantity(numVal);
  }, []);

  const profileData = useMemo(() => collections[mintState.sale.saleId as Collection], [mintState.sale]);
  const profileFootnote = profileData?.footnote ?? '';

  // No point on using callback here since most of the states are deps
  const startMint = () => {
    if (mintStep !== MintStep.Idle) {
      return;
    }

    mainSuite.analyticsService.track(AnalyticsEventName.ButtonPress, {
      buttonName: 'story_mint',
    });

    // Should never get in here
    if (!wallet?.address) {
      throw new Error(`Cannot mint for 'anon' user.`);
    }

    if (onMintStart) {
      onMintStart();
    }

    setTokenQuantity(isCustomAmount ? customAmount : selectedAmount);
    mint({
      saleId: mintState.sale.saleId,
      walletAddress: walletAddress(wallet.address),
      tokenQuantity,
    }).catch((e) => {
      log('mint from startMint error:', e);

      // any error should be caught and displayed by useMintStep
    });
  };

  // Fetch mint fee whenever we don't have it set
  useEffect(() => {
    const fetchEthEstimate = async () => {
      const response = await pipelineApiCall({
        method: 'GET',
        endpoint: PipelineEndpoint.GetMintFee,
      });
      setEthEstimate(response);
    };
    if (!ethEstimate) {
      fetchEthEstimate();
    }
  }, [ethEstimate]);

  // Timer to auto refetch mint fee
  useEffect(() => {
    const interval = setInterval(() => {
      setEthEstimate(undefined);
    }, 60000 * 5); // 5min
    () => {
      clearInterval(interval);
    };
  }, []);

  const ethCost = useMemo(
    () => (ethEstimate * (isCustomAmount ? customAmount : selectedAmount)).toFixed(5),
    [ethEstimate, isCustomAmount, customAmount, selectedAmount],
  );

  const mintBtnCopy = useMemo(() => {
    if (mintStep === MintStep.Idle) {
      if (!ethEstimate) {
        return <Spinner />;
      }
      // `Mint ≈ ${ethCost} ETH`
      return `Mint`;
    }
    return buttonCopy[mintStep];
  }, [mintStep, ethEstimate, ethCost]);

  return (
    <section className={`MintBox_Container`}>
      {/* <h1 className="MintBox_Text Title">{mintState.sale?.mintCopy?.open?.mintBox?.title ?? mintState.sale?.saleName ?? 'Collectible Story'}</h1>
      <div>
        <div className="Col Profile">
          <div className="Row PicAndName">
            <img className="Picture" src={profileData?.avatar || defaultAvatar} />
            <h2 className="MintBox_Text Name">{profileData?.author || 'Author name'}</h2>
          </div>
          <p className="MintBox_Text ProfileFootnote">{profileFootnote}</p>
        </div>
      </div> */}
      <div className="Col AmountSelector">
        <p className="MintBox_Text AmountText">Select Amount</p>
        <div className="Row AmountBtns">
          <div className={`Box AmountBtn${selectedAmount === 1 ? '_selected' : ''}`} onClick={onAmountClick(1)}>
            1
          </div>
          <div className={`Box AmountBtn${selectedAmount === 5 ? '_selected' : ''}`} onClick={onAmountClick(5)}>
            5
          </div>
          <div className={`Box AmountBtn${selectedAmount === 10 ? '_selected' : ''}`} onClick={onAmountClick(10)}>
            10
          </div>
          <div className={`Box CustomAmountBtn AmountBtn${isCustomAmount ? '_selected' : ''}`} onClick={onCustomAmountClick}>
            Custom
          </div>
        </div>
        {isCustomAmount && (
          <>
            <div className="Row CustomAmountPicker">
              <div className={`Box AmountBtn`} onClick={onMinusClick}>
                -
              </div>
              <input className="CustomAmountInput" value={customAmount} onChange={onCustomAmountInputChange} />
              <div className={`Box AmountBtn`} onClick={onPlusClick}>
                +
              </div>
            </div>
            <p className="MintBox_Text AmountText MaxPerAddress">Max per transaction: {MAX_INPUT}</p>
          </>
        )}
        <div className="Row MintPrice">
          <p className="MintBox_Text AmountText">
            Free x{tokenQuantity} {tokenQuantity === 1 ? 'NFT' : 'NFTs'}
            <span>0.00 ETH</span>
          </p>
          <p className="MintBox_Text AmountText">
            Platform Fee <span>≈{ethCost} ETH</span>
          </p>
          <p className="MintBox_Text AmountText Total">
            Total <span>{ethCost} ETH</span>
          </p>
        </div>
      </div>

      <MintBoxFootnote />

      <div className={`button-blue ${mintStep === MintStep.Idle ? '' : 'loading disabled'}`} onClick={startMint}>
        {mintBtnCopy}
      </div>
    </section>
  );
};
