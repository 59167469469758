import React, { Suspense, useEffect, useRef, useState } from 'react';
import { BrowserRouter, Link, Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import userAgent from '@storyverseco/user-agent';
import './App.scss';
import { LoadState } from './common/LoadState';
import { useClassName } from './hooks/useClassName';
import { TopLink } from './components/top-link/TopLink';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { AppActionType, AppProvider, useAppDispatch, useAppState } from './context/AppContext';
import { environment } from './environments/environment';
// import { AdminPage } from './pages/admin/AdminPage'
import { CommunityGuidelinesPage } from './pages/community-guidelines/community-guidelines';
import { ContactPage } from './pages/contact/ContactPage';
import { CreateLandingPage } from './pages/create-landing/create-landing';
import { CreatePage } from './pages/create/CreatePage';
// import { FaqPage } from './pages/faq/FaqPage'
import { FoundersPage } from './pages/founders/FoundersPage';
// import { HomePage } from './pages/home/HomePage'
import { LoginPage } from './pages/login/LoginPage';
import { LogoutPage } from './pages/login/LogoutPage';
import { MapsPage } from './pages/maps/MapsPage';
import { MintPage } from './pages/mint-v2/MintPage';
import { ViewPage } from './pages/view/ViewPage';
import { WhitepaperPage } from './pages/whitepaper/WhitepaperPage';
import './services';
import { AnalyticsEventName } from './services/analytics/AnalyticsEventName';
import { mainSuite, MainSuiteSetup } from './services/ServiceFactory';
import { UserProvider, useUserState } from './services/user/UserContext';
import { useUserService } from './hooks/useUserService';
import { Features } from './context/FeaturesState';
import { SearchParam } from './common/SearchParam';
import { GameSiteUrlParams } from '@storyverseco/svs-consts';
import { CommunityPageRedirect } from './pages/community/CommunityPageRedirect';
import { MintPageState, MintProvider, useMintState } from './context/mint/MintContext';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { debug } from '@common/LogWrapper';
import { PathParam } from './common/PathParam';
import { MintCollectionPage } from './pages/mint-collections/MintCollectionPage';
import { eagerLoadTwitterLibrary } from 'react-twitter-widgets';
import { MintHomePage } from './pages/mint-home/MintHomePage';
import { SecretMenu } from '@components/SecretMenu/SecretMenu';
import { ViewDefaultStory } from './pages/view/ViewDefaultStory';
import BottomNavBar from './pages/mint-home/components/BottomNavBar';
import { FakeOnboard } from './pages/fakeonboard/FakeOnboard';
import { Notifications } from './pages/notifications/Notifications';
import { CreateGem } from './pages/create-gem/createGem';

eagerLoadTwitterLibrary();

// @TODO: Use DNS from cfg
Sentry.init({
  dsn: 'https://5d3df4d168bf42e285f1531128b5b2c5@o4505230490861568.ingest.sentry.io/4505234844024832',
  integrations: [new BrowserTracing()],
  autoSessionTracking: true,
  release: process.env.VERSION,
  environment: environment.envName,
});

const versionToken = 'VERSION';
const log = debug('app:app');

window.debugApp = debug;
window['log'] = debug('app:misc');

if (environment.production) {
  debug.disable();
} else {
  debug.enable('*');
}

// lazy load these pages since they're big or have lots of text
const CookiesPage = React.lazy(() => import('./pages/terms/CookiesPage'));
const Cookies20220420Page = React.lazy(() => import('./pages/terms/previous/Cookies20220420Page'));
const FoundersPassTermsPage = React.lazy(() => import('./pages/terms/FoudersPassTermsPage'));
const CharacterPassTermsPage = React.lazy(() => import('./pages/terms/CharacterPassTermsPage'));
const PrivacyNoticePage = React.lazy(() => import('./pages/terms/PrivacyNoticePage'));
const PrivacyNotice20220420Page = React.lazy(() => import('./pages/terms/previous/PrivacyNotice20220420Page'));
const PrivacyCaNoticePage = React.lazy(() => import('./pages/terms/PrivacyCaNoticePage'));
const TermsOfServicePage = React.lazy(() => import('./pages/terms/TermsOfServicePage'));
const Terms20220420Page = React.lazy(() => import('./pages/terms/previous/Terms20220420Page'));
const CopyrightPage = React.lazy(() => import('./pages/terms/CopyrightPage'));
const Copyright20220420Page = React.lazy(() => import('./pages/terms/previous/Copyright20220420Page'));
const PLOTTerms20220420Page = React.lazy(() => import('./pages/terms/previous/PLOTTerms20220420Page'));
const CollectibleGiveawaysPage = React.lazy(() => import('./pages/terms/CollectibleGiveawaysPage'));
const CollectibleTermsPage = React.lazy(() => import('./pages/terms/CollectibleTermsPage'));
export const ModalRootId = 'modal-root';

function LoadingSection() {
  return <div className="container py-2">Loading...</div>;
}

function Layout() {
  const userService = useUserService();
  const appState = useAppState();
  const appDispatch = useAppDispatch();
  const mintState = useMintState();
  const location = useLocation();
  const userAccount = useUserState();
  const initialized = appState.readyState === LoadState.Loaded;
  // const mainClassName = useClassName('main-con', appState.mainConFullHeight && 'full-height');
  const mainClassName = 'main-con'; // useClassName('main-con', '');

  useEffect(() => {
    if (!appState?.initialSearchParams) {
      return;
    }
    if (initialized) {
      return;
    }
    appDispatch({
      type: AppActionType.UpdateReadyState,
      readyState: LoadState.Loaded,
    });
  }, [appState]);

  // useEffect(() => {
  //   const doc = document.documentElement;
  //   if (appState.mainConFullHeight) {
  //     doc.classList.add('full-height');
  //   } else {
  //     doc.classList.remove('full-height');
  //   }
  // }, [appState.mainConFullHeight]);

  useEffect(() => {
    // track link source ids
    if (!appState?.initialSearchParams) {
      return;
    }
    if (appState.initialSearchParams.has(SearchParam.LinkId)) {
      const linkId = appState.initialSearchParams.get(SearchParam.LinkId);
      userService.waitForInit().then(() => {
        mainSuite.analyticsService.setPersistedOptions({ srcLinkId: linkId });
      });
    }
  }, [appState.initialSearchParams]);

  useEffect(() => {
    if (!appState?.initialSearchParams) {
      return;
    }

    let changed = false;
    const features: Partial<Features> = {};
    if (appState.initialSearchParams.has(SearchParam.SignUp)) {
      features.showContactsSection = true;
      features.showFooterSignUp = true;
      features.showSignUpModal = true;
      changed = true;
    }
    if (appState.initialSearchParams.has(SearchParam.Map) || appState.initialSearchParams.has(SearchParam.Maps)) {
      features.showMapLink = true;
      changed = true;
    }
    if (appState.initialSearchParams.has(SearchParam.Whitepaper)) {
      features.showWhitepaperLink = true;
      changed = true;
    }
    if (appState.initialSearchParams.has(SearchParam.Tokenproof)) {
      features.showTokenproof = true;
      changed = true;
    }

    if (changed) {
      appDispatch({
        type: AppActionType.UpdateFeatures,
        features,
      });
    }
  }, [appState.initialSearchParams]);

  useEffect(() => {
    appDispatch({
      type: AppActionType.UpdateReadyState,
      readyState: LoadState.Loading,
    });

    userService.waitForInit().then(() => {
      // the very initial properties
      mainSuite.analyticsService.setUserState(userAccount);
    });

    if (!appState.initialSearchParams) {
      appDispatch({
        type: AppActionType.UpdateSearchParams,
        searchParams: location.search,
      });
    }

    setTimeout(() => {
      const doc = document.documentElement;
      doc.classList.add('tp-ready');
    }, 2000);
  }, []);

  useEffect(() => {
    Sentry.setContext('mintContext', {
      loadState: LoadState[mintState.loadState],
      pageState: MintPageState[mintState.pageState],
      coldWallet: mintState.coldWallet,
      hotWallet: mintState.hotWallet,
    });
  }, [mintState.loadState, mintState.pageState, mintState.coldWallet, mintState.hotWallet]);

  // @todo: find a way to optimize this out when not on Telegram
  // Load telegram sdk so we can make site full screen within Telegran in-app browser (does nothing in regular browser)
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-web-app.js';
    script.async = true;

    // This function will be called once the script has loaded
    script.onload = () => {
      if (window.Telegram) {
        window.Telegram.WebApp.expand();
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // set twitter handle as persisted options and user properties on load
  useEffect(() => {
    const { navbarService, analyticsService } = mainSuite;

    navbarService.api.twitterService.auth.get().then((twitterUser) => {
      if (twitterUser) {
        analyticsService.userTrack({
          twitterHandle: twitterUser.handle,
        });
        analyticsService.setPersistedOptions({
          twitterHandle: twitterUser.handle,
        });
      }
    });
  }, []);

  const mainRef = useRef<HTMLElement>();

  return (
    <>
      {/* {!appState.showOnboard && <Header />} */}
      <main ref={mainRef} className={mainClassName}>
        {initialized ? (
          <Suspense fallback={<LoadingSection />}>
            <Outlet />
          </Suspense>
        ) : (
          <LoadingSection />
        )}
      </main>
      {!appState.showOnboard && <BottomNavBar />}
      {/* <Footer />
      {/* <div id={ModalRootId} /> */}
    </>
  );
}

function NotFound() {
  return <Navigate to="/" />;
}

function Providers({ children }) {
  // return (
  //   <AppProvider>
  //     <UserProvider>
  //       <MintProvider>{children}</MintProvider>
  //     </UserProvider>
  //   </AppProvider>
  // );

  return (
    <AppProvider>
      <UserProvider>
        <MintProvider>
          <MainSuiteSetup />
          {children}
          {/* <CookieConsent location="bottom" buttonText="Accept" enableDeclineButton declineButtonText="Reject" containerClasses="svs-cookie-popup">
            <p>Disclosure</p>
            We use cookies to give you the best experience on Storyverse.
            <br />
            <a href="/privacy">Learn more</a>
          </CookieConsent> */}
        </MintProvider>
      </UserProvider>
    </AppProvider>
  );
}

function App() {
  const basename = environment.routerBasename?.replace(versionToken, process.env.VERSION) ?? undefined;
  useEffect(() => {
    mainSuite.analyticsService.userTrack({
      userAgent: userAgent.string,
      DEVICE_TYPE: userAgent.isMobile ? 'mobile' : 'desktop',
      OS_TYPE: userAgent.os.name,
      OS_VERSION: userAgent.os.version,
      BROWSER_TYPE: userAgent.browser.name,
      BROWSER_VERSION: userAgent.browser.version,
      APP_TYPE: userAgent.app.name,
      APP_VERSION: userAgent.app.version,
    });
    mainSuite.analyticsService.track(AnalyticsEventName.GameStart);
    console.log('Site version:', process.env.VERSION);
  }, []);

  const isViewerOrEditorPage = location.pathname.includes('view') || location.pathname === '/create';

  if (isViewerOrEditorPage) {
    document.body.style.overflow = 'hidden';
  }

  useEffect(() => {
    if (!isViewerOrEditorPage) {
      mainSuite.analyticsService.init();
    }
  }, []);

  // return (
  //   <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
  //     <Providers>
  //       <BrowserRouter basename={basename}>
  //         <Routes>
  //           <Route path="/" element={<Layout />}>
  //             <Route index element={<MintHomePage />} />
  //             <Route path="index.html" element={<MintHomePage />} />
  //           </Route>
  //           <Route path="*" element={<NotFound />} />
  //         </Routes>
  //       </BrowserRouter>
  //       {/* <SecretMenu /> */}
  //     </Providers>
  //   </Sentry.ErrorBoundary>
  // );

  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Providers>
        <BrowserRouter basename={basename}>
          <Routes>
            <Route path="/" element={<Layout />}>
              {/* <Route index element={<MintHomePage />} /> */}
              <Route path="cp/bayc" element={<Navigate to="/" />} />
              <Route path="index.html" element={<MintHomePage />} />
              <Route path="about" element={<CreateLandingPage />} />
              <Route path="community" element={<CommunityGuidelinesPage />} />
              {/* <Route path="old-home" element={<HomePage />} /> */}
              <Route path="login" element={<LoginPage />} />
              <Route path="logout" element={<LogoutPage />} />
              <Route path="whitepaper" element={<WhitepaperPage />} />
              <Route path="founders" element={<FoundersPage />} />
              {/* <Route path="faq" element={<FaqPage />} /> */}
              <Route path="faq" element={<Navigate to="/about#faq" />} />
              <Route path="contact" element={<ContactPage />} />
              {/* <Route path="collections" element={<CollectionsPage />} /> */}

              <Route path="mint2" element={<MintCollectionPage id="1" />} />
              <Route path="home" element={<MintHomePage />} />

              <Route path="mint" element={<MintPage />} />
              <Route path={`mint/:${PathParam.SaleId}`} element={<MintPage />} />
              <Route path="characterpass" element={<MintPage saleType="characterPass" />} />
              <Route path={`characterpass/:${PathParam.SaleId}`} element={<MintPage saleType="characterPass" />} />
              <Route path="collections" element={<MintPage saleType="collection" />} />
              <Route path={`collections/:${PathParam.SaleId}`} element={<MintPage saleType="collection" />} />
              <Route path={`raffle/:${PathParam.RaffleURI}`} element={<MintPage saleType="collection" />} />

              {/* character pass pages */}
              {/* <Route path="bayc" element={<MintPage saleId="BAYC" />} />
              <Route path="mayc" element={<MintPage saleId="MAYC" />} />
              <Route path="cryptopunks" element={<MintPage saleId="CPCP" />} />
              <Route path="PudgyPenguins" element={<MintPage saleId="PPCP" />} />
              <Route path="doodles" element={<MintPage saleId="DOODLECP" />} /> */}
              {/* / character pass pages */}

              {/* story pages */}
              {/* <Route path="fezlove" element={<MintPage saleId="svsfl" />} />
              <Route path="chasingshadows" element={<MintPage saleId="svscs" />} />
              <Route path="foreplay" element={<MintPage saleId="svsfp" />} />
              <Route path="raymondlightyear" element={<MintPage saleId="svsrl" />} />
              <Route path="emptyhorizon" element={<MintPage saleId="svseh" />} />
              <Route path="soundclash" element={<MintPage saleId="svssc" />} />
              <Route path="prisonrush" element={<MintPage saleId="svspr" />} />
              <Route path="barmanronan" element={<MintPage saleId="svsbrm" />} /> */}
              {/* / story pages */}

              <Route path="maps" element={<MapsPage />} />
              <Route path="map" element={<Navigate to="/maps" />} />
              <Route path="cookies" element={<CookiesPage />} />
              <Route path="cookies-2022-04-20" element={<Cookies20220420Page />} />
              <Route path="founderspassterms" element={<FoundersPassTermsPage />} />
              <Route path="characterpassterms" element={<CharacterPassTermsPage />} />
              <Route path="collectibleterms" element={<CollectibleTermsPage />} />
              <Route path="collectiblegiveaways" element={<CollectibleGiveawaysPage />} />
              <Route path="privacy" element={<PrivacyNoticePage />} />
              <Route path="privacy-2022-04-20" element={<PrivacyNotice20220420Page />} />
              <Route path="privacy-ca" element={<PrivacyCaNoticePage />} />
              <Route path="copyright" element={<CopyrightPage />} />
              <Route path="copyright-2022-04-20" element={<Copyright20220420Page />} />
              <Route path="tos" element={<TermsOfServicePage />} />
              <Route path="tos-2022-04-20" element={<Terms20220420Page />} />
              <Route path="plots-2022-04-20" element={<PLOTTerms20220420Page />} />

              {/* <Route path="create"> */}
              {/* <Route index element={<CreatePage />} /> */}
              {/* creator ai page, should redirect to particular story */}
              {/* <Route path={'ai'} element={<CommunityPageRedirect isMaster />}></Route> */}
              {/* <Route path={`:${GameSiteUrlParams.CommunityShortcut}/ai`} element={<CommunityPageRedirect />}></Route> */}
              {/* creator page with community template url  */}
              {/* <Route path={`:${GameSiteUrlParams.Template}`} element={<CreatePage />} /> */}
              {/* creator page with wallet address and story index */}
              {/* <Route path={`:${GameSiteUrlParams.WalletAddress}/:${GameSiteUrlParams.StoryId}`} element={<CreatePage />} /> */}
              {/* <Route path={`nft/:${GameSiteUrlParams.WalletAddress}/:${GameSiteUrlParams.StoryId}`} element={<CreatePage isNFT />} /> */}
              {/* </Route> */}
              {/* Why don't we have a base route for view like we have for create? */}
              <Route path={`view/:${GameSiteUrlParams.WalletAddress}/:${GameSiteUrlParams.StoryId}`} element={<ViewPage />} />
              {/* viewer with ai */}
              <Route path={`view/:${GameSiteUrlParams.WalletAddress}/:${GameSiteUrlParams.StoryId}/ai`} element={<ViewPage isAi />} />
              {/* viewer with nft */}
              <Route path={`view/:${GameSiteUrlParams.WalletAddress}/:${GameSiteUrlParams.StoryId}/n`} element={<ViewPage isNFT />} />
              {/* viewer with default nft named story  */}
              <Route path={`view/:${PathParam.StoryName}/story`} element={<ViewDefaultStory />} />
              {/* viewer with nft and token */}
              <Route
                path={`view/:${GameSiteUrlParams.WalletAddress}/:${GameSiteUrlParams.StoryId}/n/:${GameSiteUrlParams.TokenID}`}
                element={<ViewPage isNFT />}
              />
              {/* deep link to a specific timeline and step */}
              <Route
                path={`view/:${GameSiteUrlParams.WalletAddress}/:${GameSiteUrlParams.StoryId}/n/:${GameSiteUrlParams.TokenID}/:${GameSiteUrlParams.DeeplinkTimelineId}/:${GameSiteUrlParams.DeeplinkStoryId}`}
                element={<ViewPage isNFT />}
              />
              {/* deep link to a specific timeline and step with choice selected */}
              <Route
                path={`view/:${GameSiteUrlParams.WalletAddress}/:${GameSiteUrlParams.StoryId}/n/:${GameSiteUrlParams.TokenID}/:${GameSiteUrlParams.DeeplinkTimelineId}/:${GameSiteUrlParams.DeeplinkStoryId}/:${GameSiteUrlParams.DeeplinkChoiceId}`}
                element={<ViewPage isNFT />}
              />
              <Route path="caption-contest/alpha" element={<TopLink to={environment.captionContestUrl} />} />
              {/* <Route path="admin" element={<AdminPage />} /> */}
              {/*
                  NEW ROUTES!!!!
                */}
              <Route index element={<FakeOnboard />} />
              <Route path={`notifications`} element={<Notifications />} />
              <Route path={`create`} element={<MintHomePage showCreate />} />
              <Route path={`:${PathParam.SalePath}`} element={<MintHomePage />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
        <SecretMenu />
      </Providers>
    </Sentry.ErrorBoundary>
  );
}

export default App;
