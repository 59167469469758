import './CollectionsFormSuccess.scss';
import { useMintState } from '@context/mint/MintContext';
import { AnalyticsEventName } from '@services/analytics/AnalyticsEventName';
import { mainSuite } from '@services/ServiceFactory';
import { MouseEvent } from 'react';

export type CollectionsFormSuccessProps = {};

function recordClick(e: MouseEvent, buttonName: string) {
  e.currentTarget.classList.add('completed');
  mainSuite.analyticsService.track(AnalyticsEventName.ButtonPress, {
    buttonName,
  });
}

export function CollectionsFormSuccess({}: CollectionsFormSuccessProps) {
  const { sale } = useMintState();
  const crossLinks = sale?.crossLinks ?? [];

  return (
    <div className="coll-form-success">
      <div className="coll-form-success-congrats">Congrats!</div>
      {/* <div className="coll-form-success-message">You're now registered to win a FREE Collectible Story!</div> */}
      <div className="coll-form-success-message">Join us in telegram to find out what's next</div>
      <a
        href="https://t.me/+65yCsJsQ8qo2ZWEx"
        target="_blank"
        // className="mint-btn-cta extra-button"
        className="button-blue"
        onClick={(e) => {
          recordClick(e, 'cross-link-join-telegram');
        }}
      >
        Join Telegram
      </a>
    </div>
  );

  return (
    <div className="coll-form-success">
      <div className="coll-form-success-congrats">Congrats!</div>
      <div className="coll-form-success-message">You're now registered to win a FREE Collectible Story!</div>
      <div className="coll-form-success-message">
        <strong>Here are more ways to increase your chances:</strong>
      </div>
      <ol className="extra-btns-list">
        <li>
          <a
            href="https://opensea.io/collection/storyverse-founders-pass"
            target="_blank"
            className="mint-btn-cta extra-button founder-pass"
            onClick={(e) => {
              recordClick(e, 'cross-link-founder-pass');
            }}
          >
            Buy a Founders Pass
          </a>
        </li>

        {crossLinks.map(({ name, url }) => (
          <li key={name}>
            <a
              href={url}
              target="_blank"
              className={`mint-btn-cta extra-button cross-link-${name}`}
              onClick={(e) => {
                recordClick(e, `cross-link-${name}`);
              }}
            >
              Register for {name}
            </a>
          </li>
        ))}

        <li>
          <a
            href="https://discord.gg/storyverse"
            target="_blank"
            className="mint-btn-cta extra-button join-discord"
            onClick={(e) => {
              recordClick(e, 'cross-link-join-discord');
            }}
          >
            Join our Discord
          </a>
        </li>
      </ol>
    </div>
  );
}

export default CollectionsFormSuccess;
