import './StepBox.scss';
import React, { CSSProperties, MouseEvent, useCallback } from 'react';
import walletNew from '@assets/svg/wallet-new.svg';
import twitterIcon from '@assets/svg/twitter-icon.svg';
import { useClassName } from '@hooks/useClassName';
import { ArrowIcon } from './ArrowIcon';

const icons = {
  wallet: walletNew,
  twitter: twitterIcon,
};

type StepIcon = keyof typeof icons;

interface BtnProps {
  show: boolean;
  label?: string;
  disabled?: boolean;
  showArrow?: boolean;
  buttonIcon?: string;
  onClick?: (e?: any) => void;
}

interface StepBoxProps {
  disabled: boolean;
  btn?: BtnProps;
  connected?: boolean;
  title: React.ReactNode;
  subTitle: string;
  icon?: StepIcon;
  iconColor?: string;
  done: boolean;
}

const StepButton = ({ btn }: { btn: BtnProps }) => {
  if (btn.show) {
    return <ConnectBtn label={btn.label} disabled={btn.disabled} showArrow={btn.showArrow} onClick={btn.onClick} buttonIcon={btn.buttonIcon} />;
  }

  return null;
};

export const StepBox = ({ disabled, btn, connected, title, subTitle, icon, iconColor, done }: StepBoxProps) => (
  <>
    {console.warn('>>> StepBox', disabled, title, subTitle)}
    <div className={`step-container done ${disabled ? 'step-disabled' : ''}`}>
      <div className="step-content-wrapper">
        {icon && <StepIcon icon={icon} color={iconColor} />}
        <StepContent title={title} label={subTitle} done={done} />
      </div>
      <StepButton btn={btn} />
    </div>
  </>
);

interface CBtnProps {
  onClick: (e?: MouseEvent) => void;
  disabled?: boolean;
  label: string;
  showArrow?: boolean;
  buttonIcon?: string;
}

export const ConnectBtn = ({ label, disabled, onClick, showArrow = true, buttonIcon = '' }: CBtnProps) => {
  {
    console.warn('>>> ConnectBtn', disabled, label);
  }
  // const className = useClassName('connect-btn', disabled && 'disabled', buttonIcon && 'with-icon');

  const className = useClassName('connect-btn button-blue', disabled && 'disabled', buttonIcon && 'with-icon');

  const onBtnClick = useCallback(
    (e: MouseEvent) => {
      if (disabled) {
        return;
      }
      onClick(e);
    },
    [disabled, onClick],
  );
  return (
    <div className={className} onClick={onBtnClick}>
      {buttonIcon && <img className="button-icon" src={buttonIcon} />}
      {label}
      {/* <span className="label">{label}</span> */}
      {showArrow && (
        <span className="arrow-wrapper">
          <ArrowIcon />
        </span>
      )}
    </div>
  );
};

interface SCProps {
  title: React.ReactNode;
  label: string;
  done: boolean;
}

export const StepContent = ({ title, label, done }: SCProps) => (
  <div className="step-content">
    <p className="step-title">{title}</p>
    <div className="step-label-container">
      <p className={`label ${done ? 'lgreen' : 'lgrey'}`}>{label}</p>
    </div>
  </div>
);

interface SIProps {
  icon: StepIcon;
  color?: string;
}

export const StepIcon = ({ icon, color }: SIProps) => {
  const style: CSSProperties = {};
  if (color) {
    style.backgroundColor = color;
    style.WebkitMaskImage = `url('${icons[icon]}')`;
    style.WebkitMaskRepeat = 'no-repeat';
    style.WebkitMaskSize = 'contain';
    style.maskImage = `url('${icons[icon]}')`;
    style.maskRepeat = 'no-repeat';
    style.maskSize = 'contain';
    // console.log('StepIcon style', style);
  } else {
    style.backgroundImage = `url('${icons[icon]}')`;
  }
  return <div className="step-icon" style={style}></div>;
};
// export const StepIcon = ({ icon }: SIProps) =>  (
//   <img
//     alt="Icon"
//     srcSet={`${icons[icon]} 1x, ${icons[icon]} 2x`}
//     src={icons[icon]}
//     width="66"
//     height="66"
//     decoding="async"
//     data-nimg="future"
//     loading="lazy"
//     className="step-icon"
//     id="Color-test"
//   />
// );

// #2b30f5

// export function FooBar({address}: {address?: string}) {

//   const selectedContent = React.useMemo(() => {
//     if (!address) {
//       return {
//         borderClass: 'css-1lx16x6',
//         title: 'Connect your wallet',
//         subTitle: 'Start the whitelist process',
//       }
//     }

//     const addressStart = address.substring(0, 4);

//     const addressEnd = address.substring(address.length-5, address.length-1);

//     return {
//       borderClass: 'css-11gq7t',
//       title: `Hello, ${addressStart}...${addressEnd}`,
//       subTitle: 'Wallet connected',
//     }
//   }, [address]);

//   return (
//     <>
//       <div className={`MuiGrid-root MuiGrid-container ${selectedContent.borderClass}`}>
//         <div className="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-12 css-1six6a8">
//           <img alt="Icon" srcSet="" src="" width="66" height="66" decoding="async" data-nimg="future" loading="lazy" style={{color: "transparent", width: "40px"}} />
//           <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-8 css-b878jv">
//             {/* <ConnectBox  /> */}
//             <p className="MuiTypography-root MuiTypography-body1 css-19qbhl8">{selectedContent.title}</p>
//             <div className="MuiGrid-root MuiGrid-item css-1wxaqej">
//               <p className="MuiTypography-root MuiTypography-body1 css-whj80m">{selectedContent.subTitle}</p>
//             </div>
//           </div>
//         </div>
//         {
//           address && (
//             <div className="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-12 css-1dizhzq">
//               <img alt="Green Check" srcSet=""" src="" width="25" height="25" decoding="async" data-nimg="future" loading="lazy" style={{color: "transparent", width: "16px"}} />
//               <p className="MuiTypography-root MuiTypography-body1 css-1uvc744">Connected</p>
//             </div>
//           )
//         }
//       </div>
//       {!address && <ConnectBtn label='CONNECT' onClick={() => {}} />}
//     </>
//   )
// }

// function FooBarTwitter({address}: {address?: string}) {
//   const mintState = useMintState();

//   const selectedContent = useMemo(() => {
//     if (!address) {
//       return {
//         borderClass: 'css-1lx16x6',
//         title: 'Connect your wallet',
//         subTitle: 'Start the whitelist process',
//       }
//     }

//     const addressStart = address.substring(0, 4);

//     const addressEnd = address.substring(address.length-5, address.length-1);

//     return {
//       borderClass: 'css-11gq7t',
//       title: `Hello, ${addressStart}...${addressEnd}`,
//       subTitle: 'Wallet connected',
//     }
//   }, [address]);

//   const followSocials  = mintState.sale?.followSocials;

//   return (
//     <>
//       <div className={`MuiGrid-root MuiGrid-container ${selectedContent.borderClass}`}>
//         <div className="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-12 css-1six6a8">
//           <img alt="Icon" srcSet="" src="" width="66" height="66" decoding="async" data-nimg="future" loading="lazy" style={{color: "transparent", width: "40px"}} />
//           <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-8 css-b878jv">
//             {/* <ConnectBox  /> */}
//             <p className="MuiTypography-root MuiTypography-body1 css-19qbhl8">
//               Follow, {' '}
//               {
//                 followSocials
//                 .filter((social) => social.type === SaleSocialType.Twitter)
//                 .map((social) => (
//                   <><a href={`https://twitter.com/${social.userId}`} target="_blank">{social.userId || ''}</a>{' '}{' '}</>

//                 ))
//               }

//             </p>
//             <div className="MuiGrid-root MuiGrid-item css-1wxaqej">
//               <p className="MuiTypography-root MuiTypography-body1 css-whj80m">{selectedContent.subTitle}</p>
//             </div>
//           </div>
//         </div>
//         {
//           address && (
//             <div className="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-12 css-1dizhzq">
//               <img alt="Green Check" srcSet="" src="" width="25" height="25" decoding="async" data-nimg="future" loading="lazy" style={{color: "transparent", width: "16px"}} />
//               <p className="MuiTypography-root MuiTypography-body1 css-1uvc744">Connected</p>
//             </div>
//           )
//         }
//       </div>
//       {!address && <ConnectBtn />}
//     </>
//   )
// }
